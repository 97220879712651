import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-instruction-guidlines',
  templateUrl: './instruction-guidlines.component.html',
  styleUrls: ['./instruction-guidlines.component.scss']
})
export class InstructionGuidlinesComponent {

  constructor(
    private router: Router,
  ){}

  register(){
    this.router.navigateByUrl('/register')
  }

  goToHome(){
    this.router.navigateByUrl('/home')
  } }
