import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs'

import { ErrorHandlerService } from '../../services/error-handler/error-handler.service'

import { Router } from '@angular/router'
import { LoginService } from '../../services/login/login.service'
import { AUTH_URL } from '../../constants/url'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false
  private refreshTokenSubject = new BehaviorSubject(null)

  constructor(
    private loginService: LoginService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(this.addAuthToken(request))
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.processRequestError(error, request, next)
        )
      )
  }

  addAuthToken(request: HttpRequest<any>) {

    const token = this.loginService.getAuthToken()
    const headers: any = {}

    if (
      (request.method === 'POST' ||
        request.method === 'PUT' ||
        request.method === 'PATCH') &&
      !request.headers.get('fileUpload')
    ) {
      headers['Content-Type'] = 'application/json'
    }
    if (token && !request.headers.get('isAccessTokenRequired')) {
      headers['Authorization'] = token
    }

    return request.clone({
      setHeaders: { ...headers },
    })
  }

  private processRequestError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      error instanceof HttpErrorResponse &&
      error.status === 0||error.status === 401
    ) {
      if (error.url?.indexOf(AUTH_URL.refreshToken) !== -1 ||
      error.url?.indexOf(AUTH_URL.login) !== -1 ||
      error.url?.indexOf(AUTH_URL.logout) !== -1
       ) {
        return this.handleRefreshError(error)
      }
      return this.refreshToken(request, next)
    }
    this.errorHandlerService.handle(error)
    return throwError(() => error)
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // in case the page consists of more than one requests
    if (this.refreshTokenInProgress) {
      // wait while getting new token
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthToken(request)))
      )
    } else {
      this.refreshTokenInProgress = true
      this.refreshTokenSubject.next(null)

      return this.loginService.refreshAuthToken().pipe(
        switchMap(res => {
          this.loginService.setAuthToken(res.data.accessToken)
          this.loginService.setRefreshToken(res.data.refreshToken)
          this.refreshTokenSubject.next(res.data.refreshToken)
          return next.handle(this.addAuthToken(request))
        }),
        catchError(error => {
          this.errorHandlerService.handle(error)
          return throwError(() => error)
        }),
        finalize(() => (this.refreshTokenInProgress = false))
      )
    }
  }

  private handleRefreshError(error: HttpErrorResponse) {
    this.refreshTokenInProgress = false
    return throwError(() => error)
  }
 
}