<div class="policies-header">
    <div class="background">
        <div class="header-content flex justify-content-between align-items-center ">
            <img src="assets/i-am-interested/images/kalasha.webp" (click)="routeToHome()" alt="" class="logo cursor-pointer">
            <div *ngIf="isInstructionPage" class="instruction-header">Instructions & Guidelines</div>
            <div *ngIf="isInstructionPage"></div>
        <div *ngIf="!isInstructionPage" class="flex gap-4 justify-content-center align-items-center">
        <div class="btn-group flex flex-row gap-4">
            <p-button class="btn-primary" (click)="register()"> {{'BUTTON.register' | translate}}</p-button>
            <p-button class="btn-primary" (click)="login()">{{'BUTTON.login' | translate}}</p-button>
        </div>
        </div>
        </div>
    </div>
</div>
