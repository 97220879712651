import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPrefixInput]'
})
export class PrefixInputDirective {
  private prefix: string = '+91-';

  constructor(private el: ElementRef) {
    // Set the initial value with the prefix
    this.el.nativeElement.value = this.prefix;
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputValue: string = this.el.nativeElement.value;

    // Check if the prefix is missing
    if (!inputValue.startsWith(this.prefix)) {
      // Re-add the prefix
      this.el.nativeElement.value = this.prefix + inputValue.slice(this.prefix.length);
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(event: Event) {
    // Move the cursor to the end when the input is focused
    this.el.nativeElement.setSelectionRange(this.el.nativeElement.value.length, this.el.nativeElement.value.length);
  }
}
