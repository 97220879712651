import { Component, ViewChildren } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  LoginService,
  SevaType,
  UserFlow,
} from "src/app/services/login/login.service";
import { ToastDetails, ToastType } from "../toaster/toaster.component";
import { CommonServiceService } from "src/app/services/common/common-service.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-verify-otp",
  templateUrl: "./verify-otp.component.html",
  styleUrls: ["./verify-otp.component.scss"],
})
export class VerifyOtpComponent {
  form!: FormGroup;
  formInput = ["input1", "input2", "input3", "input4"];
  maxLength = 1;
  isDisableResendOTP: boolean = false;
  resendTimeLeft: number = 45;
  resendTimeInterval: any;
  isApiProcessing = false;
  toastDetails: ToastDetails = {};
  maskedMobileNo: string | null = null;
  isAdmin: boolean = false;

  @ViewChildren("formRow") rows: any;

  constructor(private loginService: LoginService,private commonService: CommonServiceService,private translate : TranslateService, private router: Router) {
    translate.setDefaultLang(this.commonService.selectedLanguage);
    translate.use(this.commonService.selectedLanguage);
    this.form = this.toFormGroup(this.formInput);
    if (!this.loginService.userDetails.mobileNo) {
      this.router.navigateByUrl("/home");
    }
    if (this.router.url.includes("/admin/verify-otp")) {
      this.isAdmin = true;
    }
  }

  get isLoginFlow() {
    return this.loginService.userFlow === UserFlow.LOGIN;
  }

  ngOnInit() {
    const mobileNo = this.loginService.userDetails.mobileNo.split("-");
    const lastFourDigits = mobileNo[1].slice(-4);
    this.maskedMobileNo =
      mobileNo[0] +
        "-" +
        "*".repeat(Math.max(0, mobileNo[1].length - 4)) +
        lastFourDigits || null;
  }

  routeToHome(){
    this.router.navigateByUrl('/home')
  }

  toggleLanguage(){
    const newLang = this.commonService.selectedLanguage === 'en' ? 'hi' : 'en';
    this.translate.use(newLang);
    this.commonService.selectedLanguage = newLang;
  }

  toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach((key: string | number) => {
      group[key] = new FormControl("", Validators.required);
    });
    return new FormGroup(group);
  }

  isFormControlValid(fieldName: string) {
    return (
      this.form.get(fieldName)?.invalid && this.form.get(fieldName)?.touched
    );
  }

  keyUpEvent(event: any, index: any) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
      this.rows._results[pos].nativeElement.select();
    }
  }

  resendOtp() {
    if (!this.isDisableResendOTP) {
      this.isDisableResendOTP = true;
      this.sendOtpToLogin();
      this.startTimer();
    }
  }

  startTimer() {
    this.resendTimeInterval = setInterval(() => {
      if (this.resendTimeLeft > 0) {
        this.resendTimeLeft--;
      } else {
        this.resendTimeLeft = 45;
        this.isDisableResendOTP = false;
        clearInterval(this.resendTimeInterval);
      }
    }, 1000);
  }

  sendOtpToLogin() {
    this.isApiProcessing = true;
    const loginFlag =
      this.loginService.userFlow === UserFlow.LOGIN ? true : false;
    const requestBody = {
      isAdmin: this.isAdmin,
      loginFlag: loginFlag,
      mobileNumber: this.loginService.userDetails.mobileNo,
    };
    this.loginService.sendOtp(requestBody).subscribe({
      next: (response) => {
        this.isApiProcessing = false;
        this.loginService.userDetails.sessionId = response.data.Details;
        if (
          this.router.url.includes("/admin/login") ||
          this.router.url.includes("/admin/verify-otp")
        ) {
          this.router.navigateByUrl("/admin/verify-otp");
        } else {
          this.router.navigateByUrl("/verify-otp");
        }
      },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  loginSubmitOtp() {
    this.isApiProcessing = true;
    const body = {
      mobileNumber: this.loginService.userDetails?.mobileNo,
      otp: Object.values(this.form.value).join(""),
      otpSessionId: this.loginService.userDetails?.sessionId,
    };
    this.loginService.userLogin(body).subscribe({
      next: (res) => {
        this.isApiProcessing = false;
        if (res.resultInfo.codeId === "17001001") {
          this.loginService.setAuthToken(res.data.accessToken);
          this.loginService.setRefreshToken(res.data.refreshToken);
          const userDetail = {
            userId: res.data.loggedInUserId,
            name: res.data.name,
            userNumber: res.data.loggedInUserName,
          };
          this.loginService.setLoggedInUserDetail(userDetail);
          this.isApiProcessing = false;
          if (this.isAdmin) {
            this.router.navigateByUrl("/admin/applications");
          } else {
            if (
              this.loginService.userDetails?.sevaType === SevaType.KALASHOTSAVA
            ) {
              this.router.navigateByUrl("/kalashotsava");
            } else {
              this.router.navigateByUrl("/other-sevas");
            }
          }
        } else if (res.resultInfo.codeId === "17001003") {
          this.toastDetails = {
            toastType: ToastType.ERROR,
            detail: res.resultInfo.message,
            closable: false,
            sticky: false,
          };
        }
      },
      error: (err) => {
        this.isApiProcessing = false;
        this.isApiProcessing = false;
        let errorDetail =
        err.error && err.error.resultInfo
          ? err.error.resultInfo.message
          : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  registerSubmitOtp() {
    this.isApiProcessing = true;
    const body = {
      mobileNumber: this.loginService.userDetails?.mobileNo,
      otp: Object.values(this.form.value).join(""),
      otpSessionId: this.loginService.userDetails?.sessionId,
    };

    this.loginService.verifyRegisterOtp(body).subscribe({
      next: (response) => {
        this.loginService.registrationFormValues = null
        this.isApiProcessing = false;
        if (response.resultInfo.codeId === "17001001") {
        this.loginSubmitOtp();
        this.router.navigateByUrl("/home");
        this.loginService.userFlow = UserFlow.LOGIN;
      }else if(response.resultInfo.codeId === "17001003"){
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: response.resultInfo.message,
          closable: false,
          sticky: false,
        };
      }
    },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  adminSubmitOtp() {
    this.isApiProcessing = true;
    const body = {
      mobileNumber: this.loginService.userDetails?.mobileNo,
      otp: Object.values(this.form.value).join(""),
      otpSessionId: this.loginService.userDetails?.sessionId,
    };
    this.loginService.verifyAdminLoginOtp(body).subscribe({
      next: (response) => {
        this.isApiProcessing = false;
        this.loginService.setAuthToken(response.data.accessToken);
        this.loginService.setRefreshToken(response.data.refreshToken);
        this.loginService.setRefreshToken(response.data.refreshToken);

        const userDetail = {
          userId: response.data.loggedInUserId,
          name: response.data.loggedInUserName,
          userNumber: response.data.name,
        };
        this.loginService.setLoggedInUserDetail(userDetail);
        this.router.navigateByUrl("/admin/applications");
      },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
        err.error && err.error.resultInfo
          ? err.error.resultInfo.message
          : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  submitOtp() {
    if (this.router.url.includes("/admin/verify-otp")) {
      this.adminSubmitOtp();
    } else {
      if (this.isLoginFlow) {
        this.loginSubmitOtp();
      } else {
        this.registerSubmitOtp();
      }
    }
  }
}
