import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  BASE_URL = environment.apiUrl

  constructor(private http: HttpClient) { }

  private formatErrors(error: HttpErrorResponse) {
    return throwError(() => error)
  }

  get(path: string, options = {}): Observable<any> {
    const fullPath = this.BASE_URL + path
    return this.http
      .get(`${fullPath}`, options)
      .pipe(catchError(this.formatErrors))
  }

  put(path: string, body = {}, options = {}): Observable<any> {
    const fullPath = this.BASE_URL + path
    return this.http
      .put(`${fullPath}`, JSON.stringify(body), options)
      .pipe(catchError(this.formatErrors))
  }

  post(path: string, body = {}, options = {}): Observable<any> {
    const fullPath = this.BASE_URL + path
    return this.http
      .post(`${fullPath}`, JSON.stringify(body), options)
      .pipe(catchError(this.formatErrors))
  }

  delete(path: string, options = {}): Observable<any> {
    const fullPath = this.BASE_URL + path
    return this.http
      .delete(`${fullPath}`, options)
      .pipe(catchError(this.formatErrors))
  }

  patch(path: string, body = {}, options = {}): Observable<any> {
    const fullPath = this.BASE_URL + path
    return this.http
      .patch(`${fullPath}`, JSON.stringify(body), options)
      .pipe(catchError(this.formatErrors))
  }
}
