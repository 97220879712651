<div class="footer">
  <img src="assets/home-page/Go to top.png" alt="" class="scrollToTop" (click)="scrollToTop()" />
  <div class="robo-logo">
    <a href="https://www.robosoftin.com/" target="_blank">
      <img src="assets/robosoftlogo.svg" class="desktop" />
      <img src="assets/robosoftlogo_mbl.svg" class="mobile" />
    </a>
  </div>
    <div class="info-container">
      <div class="terms-privacy">
        <span (click)="termsAndConditions()">{{
          "MAIN_PAGE_TEXT.terms" | translate
          }}</span>
        <span (click)="privacyPolicy()">{{
          "MAIN_PAGE_TEXT.privacypolicy" | translate
          }}</span>
        <span (click)="trademarks()">{{
          "MAIN_PAGE_TEXT.trademarks" | translate
          }}</span>
      </div>
    </div>
</div>
