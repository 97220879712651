import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalashotsavaService } from '../../services/kalashotsava.service';
import { ToastDetails, ToastType } from '../toaster/toaster.component';
import { DialogService } from 'primeng/dynamicdialog';
import { LoginService } from 'src/app/services/login/login.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

export enum DateStatus {
  SELECTABLE = 'SELECTABLE',
  NOT_SELECTABLE = 'NOT_SELECTABLE',
  DISABLED = 'DISABLED',
}
@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss']
})
export class SelectDateComponent implements OnInit {

  @Input() isAdmin = false
  @Output() btnEvent = new EventEmitter()

  isApiProcessing = false;
  availableDates: any = {}

  weeks: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  monthDate: { monthName: string, dates: { date: string, value: number, dateStatus: DateStatus, isBlocked?: boolean, count?: number }[] }[] = [
    {
      monthName: '',
      dates: [{
        date: '',
        value: 0,
        dateStatus: DateStatus.DISABLED,
        isBlocked: false,
      }]
    }]
  applicantDetails: any = [];
  selectedDateValue: {
    date: string,
    value: number,
    dateStatus: DateStatus,
    isBlocked: boolean
  } | null = null;
  toastDetails: ToastDetails = {};

  constructor(private kalashotsavaService: KalashotsavaService, private dialogService: DialogService,
    private loginService: LoginService,
    private adminService: AdminService) { }
  ngOnInit() {
    this.generateMonthDates();
    this.fetchAvailableSlot()
  }

  generateMonthDates() {

    this.monthDate = [
      {
        monthName: 'January, 2024',
        dates: [
          { date: '2023-12-31', value: 31, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-01', value: 1, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-02', value: 2, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-03', value: 3, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-04', value: 4, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-05', value: 5, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-06', value: 6, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-07', value: 7, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-08', value: 8, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-09', value: 9, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-10', value: 10, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-11', value: 11, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-12', value: 12, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-13', value: 13, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-14', value: 14, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-15', value: 15, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-16', value: 16, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-17', value: 17, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-18', value: 18, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-19', value: 19, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-20', value: 20, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-21', value: 21, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-22', value: 22, dateStatus: DateStatus.DISABLED, isBlocked: false },
          { date: '2024-01-23', value: 23, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-24', value: 24, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-25', value: 25, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-26', value: 26, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-27', value: 27, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-28', value: 28, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-29', value: 29, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-30', value: 30, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-01-31', value: 31, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-01', value: 1, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-02', value: 2, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-03', value: 3, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
        ]
      },
      {
        monthName: 'February, 2024',
        dates: [
          { date: '2024-01-28', value: 28, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-01-29', value: 29, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-01-30', value: 30, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-01-31', value: 31, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-01', value: 1, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-02', value: 2, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-03', value: 3, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-04', value: 4, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-05', value: 5, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-06', value: 6, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-07', value: 7, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-08', value: 8, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-09', value: 9, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-10', value: 10, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-11', value: 11, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-12', value: 12, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-13', value: 13, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-14', value: 14, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-15', value: 15, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-16', value: 16, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-17', value: 17, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-18', value: 18, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-19', value: 19, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-20', value: 20, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-21', value: 21, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-22', value: 22, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-23', value: 23, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-24', value: 24, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-25', value: 25, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-26', value: 26, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-27', value: 27, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-28', value: 28, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-02-29', value: 29, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-03-01', value: 1, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-03-02', value: 2, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
        ]
      },
      {
        monthName: 'March, 2024',
        dates: [
          { date: '2024-02-25', value: 25, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-26', value: 26, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-27', value: 27, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-28', value: 28, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-02-29', value: 29, dateStatus: DateStatus.DISABLED, isBlocked: false, count: 0 },
          { date: '2024-03-01', value: 1, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-03-02', value: 2, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-03-03', value: 3, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-03-04', value: 4, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-03-05', value: 5, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-03-06', value: 6, dateStatus: DateStatus.SELECTABLE, isBlocked: false, count: 0 },
          { date: '2024-03-07', value: 7, dateStatus: DateStatus.SELECTABLE, isBlocked: true, count: 0 },
          { date: '2024-03-08', value: 8, dateStatus: DateStatus.SELECTABLE, isBlocked: true, count: 0 },
          { date: '2024-03-09', value: 9, dateStatus: DateStatus.SELECTABLE, isBlocked: true, count: 0 },
          { date: '2024-03-10', value: 10, dateStatus: DateStatus.SELECTABLE, isBlocked: true, count: 0 },
          { date: '2024-03-11', value: 11, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-12', value: 12, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-13', value: 13, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-14', value: 14, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-15', value: 15, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-16', value: 16, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-17', value: 17, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-18', value: 18, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-19', value: 19, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-20', value: 20, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-21', value: 21, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-22', value: 22, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-23', value: 23, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-24', value: 24, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-25', value: 25, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-26', value: 26, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-27', value: 27, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-28', value: 28, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-29', value: 29, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-30', value: 30, dateStatus: DateStatus.DISABLED },
          { date: '2024-03-31', value: 31, dateStatus: DateStatus.DISABLED },
        ]
      }
    ]
  }
  selectedDate(date: any) {
    return date.split("-").reverse().join("-");
  }

  fetchAvailableSlot() {
    this.isApiProcessing = true;
    this.kalashotsavaService.fetchAvailableSlot()
      .subscribe({
        next: res => {
          this.isApiProcessing = false
          if (res.resultInfo.codeId === "17001014") {
            res.data.availableSlots.forEach((slot: { availableDates: string | number; count: any; isBlocked: any; slotId: any }) => {
              this.availableDates[slot.availableDates] = {
                availableDates: slot.availableDates,
                count: slot.count,
                isBlocked: slot.isBlocked,
                slotId: slot.slotId
              };
            });

            this.monthDate.forEach((date: any) => {
              date.dates.forEach((item: any) => {
                item.isBlocked = this.availableDates[item.date]?.isBlocked
                item.count = this.availableDates[item.date]?.count
                item.slotId = this.availableDates[item.date]?.slotId
              })
            })
          }
        },
        error: err => {
          this.isApiProcessing = false
        }
      });
  }

  fetchApplicantDetails(date: any) {
    this.isApiProcessing = true;
    const reqData = {
      slotId: date.slotId
    }
    this.adminService.fetchApplicantDetails(reqData)
      .subscribe({
        next: res => {
          this.isApiProcessing = false;
          this.applicantDetails = [];
          if (res.resultInfo.codeId === "17001001") {
            this.applicantDetails = res.data.userDetails;
          }
        },
        error: err => {
          this.isApiProcessing = false
        }
      });
  }

  selectDate(date: any) {
    if (date.dateStatus === DateStatus.SELECTABLE && !date.isBlocked && !this.isAdmin) {
      this.selectedDateValue = date;
    }
    if (date.dateStatus === DateStatus.SELECTABLE && this.isAdmin) {
      this.selectedDateValue = date;
      this.fetchApplicantDetails(date);
    }
  }

  isSelected(date: any) {
    return this.selectedDateValue?.date === date.date
  }

  isDisabled(date: any) {
    return date?.dateStatus === DateStatus.DISABLED
  }

  isBlocked(date: any) {
    return date?.dateStatus === DateStatus.SELECTABLE && date.isBlocked && date.date !== this.selectedDateValue?.date
  }

  isNotBooked(date: any) {
    return date?.dateStatus === DateStatus.SELECTABLE && !date.isBlocked && date.date !== this.selectedDateValue?.date
  }

  count(date: any) {
    if (date.count > 0 && date?.dateStatus === DateStatus.SELECTABLE) {
      return date.count
    }
  }

  selectSlot() {
    if(this.selectedDateValue?.date){

    const data = {
      eventName: 'selectSlot',
      selectedDate: this.selectedDateValue?.date
    }
    this.btnEvent.emit(data)
  }
  }

  unblock() {
    const data = {
      eventName: 'unblock',
      selectedDate: this.selectedDateValue?.date
    }
    this.dialogService
        .open(ConfirmationModalComponent, {
          showHeader: false,
          styleClass: "delete-modal",
          data: "Are you sure you want to confirm the unblocking?",
        })
        .onClose.subscribe((data) => {
          if (data === "yes") {
            this.unBlockSlot(this.selectedDateValue?.date);
          }
        });
  }

  block() {
    this.dialogService
        .open(ConfirmationModalComponent, {
          showHeader: false,
          styleClass: "delete-modal",
          data: "Are you sure you want to confirm the blocking?",
        })
        .onClose.subscribe((data) => {
          if (data === "yes") {
            this.blockSlot(this.selectedDateValue?.date);
          }
        });
  }

  cancel() {
    const data = {
      eventName: 'cancel',
      selectedDate: this.selectedDateValue?.date
    }
    this.btnEvent.emit(data)
  }

  blockSlot(date: any) {
    this.isApiProcessing = true;
    const reqBody = {
      slotdate: date,
      adminId: this.loginService.getLoggedInUserDetail().userId,
    };
    this.adminService.blockSlot(reqBody).subscribe({
      next: (res) => {
        this.isApiProcessing = false;
        if (res.resultInfo.codeId === "17001019") {
          this.displayToast(ToastType.SUCCESS, res.resultInfo.message);
        } else {
          this.displayToast(ToastType.ERROR, res.resultInfo.message);
        }
        this.fetchAvailableSlot();
      },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.displayToast(ToastType.ERROR, errorDetail);
      },
    });
  }

  unBlockSlot(date: any) {
    this.isApiProcessing = true;
    const reqBody = {
      slotdate: date,
      adminId: this.loginService.getLoggedInUserDetail().userId,
    };
    this.adminService.unBlockSlot(reqBody).subscribe({
      next: (res) => {
        this.isApiProcessing = false;
        if (res.resultInfo.codeId === "17001020") {
          this.displayToast(ToastType.SUCCESS, res.resultInfo.message);
        } else {
          this.displayToast(ToastType.ERROR, res.resultInfo.message);
        }
        this.fetchAvailableSlot();
      },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.displayToast(ToastType.ERROR, errorDetail);
      },
    });
  }

  displayToast(toastType: ToastType, message: string) {
    this.toastDetails = {
      toastType: toastType,
      detail: message,
      closable: false,
      sticky: false,
    };
  }
}
