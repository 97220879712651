<p-toast position="top-center" key="tc" class="custom-toast"
    [ngClass]="{'custom-success':toastType === 'success','custom-error':toastType === 'error'}">
    <ng-template let-message pTemplate="message">
        <div class="toast-container">
            <!-- <ng-container [ngSwitch]="toastType">
                <img *ngSwitchCase="'success'" src="assets/i-am-interested/images/check_circle.png" />
            </ng-container> -->
            <div class="toast-text">{{message.detail}}</div>
        </div>
    </ng-template>
</p-toast>
