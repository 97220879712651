import { Component, Input } from '@angular/core'

@Component({
  selector: 'full-page-loader',
  templateUrl: './full-page-loader.component.html',
  styleUrls: ['./full-page-loader.component.scss'],
})
export class FullPageLoaderComponent {
  @Input() pageSpinner:boolean = false;
}
