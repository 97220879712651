<div class="success-modal-component">
    <div  class="container">
        <div class="image">
               <img class="kalasha" src="assets/kalashotsava/kalasha.png" alt="">
               <img class="background" src="assets/kalashotsava/background.svg" alt="">
        </div>
        <div class="title">
            {{message.title}} 
        </div>
        <div class="sub-title">
            {{message.subTitle}} 
        </div>
        <div class="home-btn">
            <p-button class="btn-primary uppercase" (click)="goToHome()">{{'BUTTON.goToHome' | translate}}</p-button>
          </div>
    </div>
</div>