import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  @HostListener('keypress', ['$event'])
  onKeyPress(event: any) {
    const allowedCharacters = /[0-9]/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

}
