import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService } from "primeng/dynamicdialog";
import { CommonServiceService } from "src/app/services/common/common-service.service";
import {
  ToastDetails,
  ToastType,
} from "src/app/shared/components/toaster/toaster.component";
import { KalashotsavaService } from "src/app/shared/services/kalashotsava.service";
import { lastValueFrom } from "rxjs/internal/lastValueFrom";

@Component({
  selector: "app-main-home-page",
  templateUrl: "./main-home-page.component.html",
  styleUrls: ["./main-home-page.component.scss"],
})
export class MainHomePageComponent {
  showRegisterLoginBtns = true;
  isMobile!: boolean;
  showProfile = false;
  states: any = [];
  state: any = null;
  searchByName: any = null;
  photoGallery: any = [];
  pageNo = 1;
  isLastPage: boolean = false;
  toastDetails: ToastDetails = {};
  donorBeneficiariesCount: { donors: number; beneficiaries: number } = {
    donors: 0,
    beneficiaries: 0,
  };
  hasDonorList=false;

  constructor(
    private commonService: CommonServiceService,
    private router: Router,
    private kalashotsavaService: KalashotsavaService
  ) {
    this.checkScreenWidth();
  }

  async ngOnInit() {
    this.states = this.commonService.states;
    this.getDonorBeneficiariesCount();
    await this.fetchPhotoGallery();
    this.hasDonorList=this.photoGallery.length>0
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any): void {
    this.checkScreenWidth();
  }

  private checkScreenWidth(): void {
    this.isMobile = window.innerWidth < 768;
  }

  getDonorBeneficiariesCount() {
    this.kalashotsavaService.fetchDonorBeneficiariesCount().subscribe({
      next: (res) => {
        if (res.resultInfo.codeId === "17001001") {
          this.donorBeneficiariesCount = {
            donors: res.data.donors,
            beneficiaries: res.data.beneficiaries,
          };
        } else {
          this.toastDetails = {
            toastType: ToastType.ERROR,
            detail: res.resultInfo.message,
            closable: false,
            sticky: false,
          };
        }
      },
      error: (err) => {
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  goToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  viewMore() {
    this.pageNo = this.pageNo+1;
    this.fetchPhotoGallery();
  }

  async fetchPhotoGallery(isSearch=false) {
    const param: any = {
      pageNo: this.pageNo,
      pageSize: 10,
    };
    if (this.searchByName) {
      param.searchText = this.searchByName;
    }
    if (this.state) {
      param.state = this.state;
    }
    try {
      const res = await lastValueFrom(this.commonService.fetchInitiatedDocument(param));
  
      res.data.content.forEach((item:any) => {
        item.documentDetails=item.documentDetails.filter((ele:any)=>!ele.isTemplateDocument)
      });
      if (isSearch) {
        this.photoGallery = res.data.content;
      } else {
        this.photoGallery.push(...res.data.content);
      }
  
      this.isLastPage = res.data.last;
    } catch (err) {
      console.error('An error occurred:', err);
    }
  }

  termsAndConditions() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/terms-and-conditions`])
    );

    window.open(url, "_blank");
  }

  privacyPolicy() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/privacy-policy`])
    );

    window.open(url, "_blank");
  }

  cookiesPolicy() {
    this.router.navigateByUrl("/cookies-policy");
  }

  trademarks() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/trademark`])
    );

    window.open(url, "_blank");
  }

  offerKalasha(){
    this.router.navigateByUrl(`/instructions-guidelines`)
  }
}
