import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { CommonServiceService } from "src/app/services/common/common-service.service";
import { LoginService } from "src/app/services/login/login.service";
import { ToastDetails, ToastType } from "src/app/shared/components/toaster/toaster.component";

@Component({
  selector: "app-regitration-form",
  templateUrl: "./regitration-form.component.html",
  styleUrls: ["./regitration-form.component.scss"],
})
export class RegitrationFormComponent implements OnInit {
  userRegistrationForm!: FormGroup;
  isApiProcessing = false;
  countries: any[] = [];
  states: any[] = [];
  toastDetails: ToastDetails = {};
  imageFile!: any | null;
  imgUrl: any = null;
  formData: FormData = new FormData();

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonServiceService,
    private router: Router,
    private loginService: LoginService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    if (!this.loginService.userDetails.mobileNo) {
      this.router.navigateByUrl("/home");
    }
  }
  ngOnInit(): void {
    this.buildForm();
    this.countries = this.commonService.countries;
    this.states = this.commonService.states
    this.userRegistrationForm.patchValue({ ... this.loginService.registrationFormValues})
  }

  buildForm() {
    this.userRegistrationForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      countryCode: [{ name: "India", code: "+91" }, [Validators.required]],
      mobileNumber: ["", [Validators.required]],
      email: ["", [Validators.email]],
      address: ["", [Validators.required]],
      pinCode: ["",Validators.required],
      country: [""],
    });
    this.userRegistrationForm
      .get("countryCode")
      ?.setValue({ name: "India", code: "+91" });
    this.userRegistrationForm
      .get("country")
      ?.setValue({ name: "India", code: "+91" });
    const mobileNo = this.loginService.userDetails.mobileNo.split("-");
    this.countries = this.commonService.countries;
    const foundCountryCode = this.countries.find(country => country.code === mobileNo[0] );
    this.userRegistrationForm.get('country')?.setValue(foundCountryCode)
    if (foundCountryCode.code === "+91") {
      this.userRegistrationForm.addControl(
        "state",
        new FormControl("", Validators.required)
      );
    } else {
      this.userRegistrationForm.removeControl("state");
    }
    
    this.userRegistrationForm.get("mobileNumber")?.setValue(mobileNo[1]);
    this.userRegistrationForm.get("countryCode")?.setValue(foundCountryCode);
    this.userRegistrationForm.get("mobileNumber")?.disable()
    this.userRegistrationForm.get("countryCode")?.disable()

    this.userRegistrationForm
      .get("countryCode")
      ?.valueChanges.subscribe((value) => {
        this.userRegistrationForm.patchValue(
          { country: value },
          { emitEvent: false }
        );
      });

    this.userRegistrationForm
      .get("country")
      ?.valueChanges.subscribe((value) => {
        this.userRegistrationForm.patchValue(
          { countryCode: value },
          { emitEvent: false }
        );

        if (value.name === "India") {
          this.userRegistrationForm.addControl(
            "state",
            new FormControl("", Validators.required)
          );
        } else {
          this.userRegistrationForm.removeControl("state");
        }
      });

      this.userRegistrationForm.get("mobileNumber")?.setValue(mobileNo[1]);
      this.userRegistrationForm.get("countryCode")?.setValue(foundCountryCode);
      this.userRegistrationForm.get("mobileNumber")?.disable()
      this.userRegistrationForm.get("countryCode")?.disable()
  
  }

  isFormControlValid(fieldName: string) {
    return (
      this.userRegistrationForm.get(fieldName)?.invalid &&
      this.userRegistrationForm.get(fieldName)?.touched
    );
  }

  cancel() {
    this.loginService.registrationFormValues = null
    this.router.navigateByUrl('/home')
  }

  sendOtp() {
    const requestBody = {
      loginFlag: false,
      mobileNumber: this.loginService.userDetails.mobileNo,
    };
    this.isApiProcessing=true
    this.loginService.sendOtp(requestBody).subscribe({
      next: (response) => {
        this.isApiProcessing=false
        if(response.resultInfo.codeId === "17001008"){
          this.loginService.userDetails.sessionId = response.data.Details;
          this.toastDetails = {
            toastType:ToastType.SUCCESS,
            detail:"OTP sent to your mobile number. Please verify the OTP" ,
            closable: false,
            sticky: false,
          };
          this.router.navigateByUrl("/verify-otp");
        }else{
          this.toastDetails = {
            toastType: ToastType.ERROR,
            detail:response.resultInfo.message ,
            closable: false,
            sticky: false,
          };
        }
      },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  submitForm() {
    this.userRegistrationForm.markAllAsTouched();
    if (this.userRegistrationForm.invalid) return;
    this.isApiProcessing = true;
    this.recaptchaV3Service.execute("registeruser").subscribe(
      (token) => {
    this.isApiProcessing = false;
        this.registerUserDetails();
      },
      (error) => {
        this.isApiProcessing = false;
        console.error("Error executing reCAPTCHA v3:", error);
      }
    );
  }

  registerUserDetails() {
    const formValues = this.userRegistrationForm.value;
    if (this.imageFile) {
      this.formData.append("profilePicture", this.imageFile);
    }
    this.formData.append("name", formValues.name);
    this.formData.append("mobileNumber", this.loginService.userDetails.mobileNo);
    this.formData.append("email", formValues.email);
    this.formData.append("address", formValues.address);
    this.formData.append("pinCode", formValues.pinCode);
    this.formData.append("state", formValues.state);
    this.formData.append("country", formValues.country.name);

    this.formData.forEach((value, key) => {
      console.log(key, value);
    });

    this.loginService.registrationFormValues = this.userRegistrationForm.value
    this.isApiProcessing = true;
    this.loginService.registerUserDetails(this.formData).subscribe({
      next: (response) => {
        this.isApiProcessing = false;
        this.clear()
        this.formData = new FormData();
        this.sendOtp();
      },
      error: (err) => {
        this.formData = new FormData();
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  clear() {
    this.userRegistrationForm.reset();
    this.imageFile = null;
    const imageFileElement = document.getElementById("imageFile") as HTMLInputElement;
    if (imageFileElement !== null) {
      imageFileElement.value = "";
    }
  }


  onSelectFile(event: any, type: string) {
    const maxFileSize = 5 * 1024 * 1024;
    this.imageFile = event.target.files && event.target.files[0];
    if (this.imageFile && this.imageFile.size > maxFileSize) {
      event.target.value = '';
      this.imageFile = null;
      this.toastDetails = {
        toastType: ToastType.ERROR,
        detail: 'File size exceeds 5MB. Please choose a smaller file.',
        closable: false,
        sticky: false,
      };
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(this.imageFile);
      reader.onload = (event) => {
        this.imgUrl = (<FileReader>event.target).result;
      }
    }
  }
}
