import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit{

message!:{title:string,subTitle:string}

constructor( private dialogConfig: DynamicDialogConfig,
  public restoreRef: DynamicDialogRef,
  ){}

ngOnInit(){
  this.message=this.dialogConfig.data.message
}

  goToHome(){
    this.restoreRef.close()
  }
}
