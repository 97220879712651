import { Injectable } from "@angular/core";
import { ApiService } from "../api-service/api.service";
import { AUTH_URL, REGISTER_URL } from "src/app/constants/url";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

export enum UserFlow {
  LOGIN = "LOGIN",
  REGISTRATION = "REGISTRATION",
}

export enum SevaType {
  KALASHOTSAVA = "KALASHOTSAVA",
  OTHER_SEVA = "OTHER_SEVA",
}

export interface registrationFormValues{
  name:string,
  countryCode: any,
  mobileNo: number,
  emailId: string,
  address:string,
  state:string,
  pinCode: string,
}

@Injectable({
  providedIn: "root",
})
export class LoginService {
  BASE_URL = environment.apiUrl
  userFlow: UserFlow | null = null;
  userDetails: { mobileNo: string; sessionId: string; sevaType: SevaType ,name:string|null,userId:number|null,userNumber:string|null} = {
    mobileNo: "",
    sessionId: "",
    sevaType: SevaType.KALASHOTSAVA,
    name:null,
    userId:null,
    userNumber:null
  };
  loginUserDetails:any=null
  registrationFormValues:registrationFormValues|null = null
  private accessToken: string | null = "";
  private headers = new HttpHeaders({
    'isAccessTokenRequired': 'false',
  });
  constructor(
    private apiService: ApiService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {}

  refreshAuthToken() {
    const url = AUTH_URL.refreshToken;
    const headers = {
      'isAccessTokenRequired': 'false',
      refreshToken: this.localStorageService.getRefreshToken() || "",
      "Content-Type": "application/json",
    };
    return this.apiService.get(url, {
      headers,
    });
  }

  getAuthToken() {
    if (!this.accessToken) {
      this.accessToken = this.localStorageService.getAccessToken();
    }
    return this.accessToken;
  }

  setRefreshToken(token: string) {
    this.localStorageService.setRefreshToken(token);
  }

  setAuthToken(token: string) {
    this.localStorageService.setAccessToken(token);
    this.accessToken = token;
  }

  removeTokens() {
    this.localStorageService.removeTokens();
  }

  setLoggedInUserDetail(userDetail:any) {
    this.loginUserDetails = userDetail
    this.localStorageService.setLoggedInUserDetail(userDetail);
  }

  getLoggedInUserDetail() {
    if (!this.loginUserDetails) {
      this.loginUserDetails = this.localStorageService.getLoggedInUserDetail();
    }
    return this.loginUserDetails;
  }

  removeLoggedInUserDetail() {
    this.loginUserDetails = null;
    this.localStorageService.removeLoggedInUserDetail();
  }

  logout() {
    this.clearDataAfterLogout();
    return this.apiService.post(AUTH_URL.logout,{headers:this.headers});
  }

  clearDataAfterLogout() {
    this.removeTokens();
    this.removeLoggedInUserDetail();
  }

  checkUserRegistration(body = {}) {
      return this.apiService.post(REGISTER_URL.checkRegistration, body, {headers:this.headers});
  }

  userLogin(body = {}) {
    return this.apiService.post(REGISTER_URL.userLogin, body, {headers:this.headers});
  }

  sendOtp(body = {}) {
    return this.apiService.post(REGISTER_URL.sendOtpToLogin, body, {headers:this.headers});
  }

  verifyRegisterOtp(body = {}) {
    return this.apiService.post(REGISTER_URL.verifyOtpToRegister, body, {headers:this.headers});
  }
 
  verifyAdminLoginOtp(body = {}){
    return this.apiService.post(REGISTER_URL.verifyOtpToAdminLogin, body, {headers:this.headers});
  }

  registerUserDetails(data:FormData) {
    const headers = new HttpHeaders({
      'fileUpload': 'true',
    });
    const apiUrl = this.BASE_URL + REGISTER_URL.registerUserDetails;
    return this.http.post(apiUrl, data, {headers});
  }

  // updateProfile(data:FormData) {
  //   const headers = new HttpHeaders({
  //     'fileUpload': 'true',
  //   });
  // let params = new HttpParams().set('userId', this.getLoggedInUserDetail().userId||'');
  //   const apiUrl = this.BASE_URL + REGISTER_URL.updateProfile;
  //   return this.http.post(apiUrl, data, {params,headers});
  // }
}
