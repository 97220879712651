import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonServiceService } from "src/app/services/common/common-service.service";
import {
  LoginService,
  SevaType,
  UserFlow,
} from "src/app/services/login/login.service";
import { ToastDetails, ToastType } from "../toaster/toaster.component";
import { LocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-mobile-registration",
  templateUrl: "./mobile-registration.component.html",
  styleUrls: ["./mobile-registration.component.scss"],
})
export class MobileRegistrationComponent {
  registerForm!: FormGroup;
  isAdmin = false;
  isApiProcessing = false;
  countries: any[] = [];
  toastDetails: ToastDetails = {};
  @Input() heading: string = 'BUTTON.register';
  sevaType = SevaType;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonServiceService,
    private loginService: LoginService,
    private localStorage: LocalStorageService,
    private translate : TranslateService
  ) {
    translate.setDefaultLang(this.commonService.selectedLanguage);
    translate.use(this.commonService.selectedLanguage);
    if (this.router.url.includes("/admin/login")) {
      this.isAdmin = true;
      this.loginService.userFlow = UserFlow.LOGIN;
    }
  }

  get isLoginFlow() {
    return this.loginService.userFlow === UserFlow.LOGIN;
  }

  ngOnInit() {
    this.buildForm();
    this.countries = this.commonService.countries;
    this.heading = this.isLoginFlow || this.isAdmin ? 'BUTTON.login' : 'BUTTON.register';
  }

  routeToHome(){
    this.router.navigateByUrl('/home')
  }

     
  toggleLanguage(){
    const newLang = this.commonService.selectedLanguage === 'en' ? 'hi' : 'en';
    this.translate.use(newLang);
    this.commonService.selectedLanguage = newLang;
  }

  buildForm() {
    this.registerForm = this.formBuilder.group({
      sevaType: [SevaType.KALASHOTSAVA, Validators.required],
      countryCode: [{ name: "India", code: "+91" }, [Validators.required]],
      mobileNumber: ["", [Validators.required]],
    });
  }

  isFormControlValid(fieldName: string) {
    return (
      this.registerForm.get(fieldName)?.invalid &&
      this.registerForm.get(fieldName)?.touched
    );
  }

  sendOtpToLogin() {
    this.isApiProcessing = true;
    const formValues = this.registerForm.value;
    this.loginService.userDetails.mobileNo = `${formValues.countryCode.code}-${formValues.mobileNumber}`;
    const requestBody = {
      loginFlag: true,
      isAdmin: this.isAdmin,
      mobileNumber: `${formValues.countryCode.code}-${formValues.mobileNumber}`,
    };
    this.loginService.sendOtp(requestBody).subscribe({
      next: (response) => {
        this.isApiProcessing = false;
        if(response.resultInfo.codeId === '17001004' && !this.isAdmin){
        this.loginService.userFlow=UserFlow.REGISTRATION
        this.toastDetails = {
          toastType:ToastType.ERROR,
          detail: 'User Not Registered, Please register to continue.',
          closable: false,
          sticky: false,
        };
        setTimeout(()=>{
          this.router.navigateByUrl("/registration-form");
        },1500)
        }else if(response.resultInfo.codeId === '17001004' && this.isAdmin){
          this.toastDetails = {
            toastType:ToastType.ERROR,
            detail: response.resultInfo.message,
            closable: false,
            sticky: false,
          };
          }else if(response.resultInfo.codeId === '17001008' && this.isAdmin){
            this.router.navigateByUrl("/admin/verify-otp");
          }else if(response.resultInfo.codeId === '17001008' && !this.isAdmin){
            this.router.navigateByUrl("/verify-otp");
          }
        this.loginService.userDetails.sessionId = response.data.Details;
      },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.toastDetails = {
          toastType: ToastType.ERROR,
          detail: errorDetail,
          closable: false,
          sticky: false,
        };
      },
    });
  }

  sendOtpToRegister() {
    this.registerForm.markAllAsTouched()
    if( this.registerForm.invalid)return
    const formValues = this.registerForm.value;
    this.loginService.userDetails.mobileNo = `${formValues.countryCode.code}-${formValues.mobileNumber}`;
    const requestBody = {
      mobileNum: `${formValues.countryCode.code}-${formValues.mobileNumber}`,
    };
    this.isApiProcessing = true;
    this.loginService.checkUserRegistration(requestBody).subscribe({
      next: (response) => {
        this.isApiProcessing = false;
          if (response.resultInfo.codeId ==="17001006"){
            this.loginService.userFlow = UserFlow.LOGIN;
              this.sendOtpToLogin();
          }   
          else  if (["17001004","17001005"].includes(response.resultInfo.codeId)) {
            this.loginService.userFlow = UserFlow.REGISTRATION;
            this.router.navigateByUrl("/registration-form");
          }
      },
      error: (err) => {
        this.isApiProcessing = false;
        let errorDetail =
          err.error && err.error.resultInfo
            ? err.error.resultInfo.message
            : "Internal Server Error";
        this.toastDetails = {
          detail: errorDetail,
          closable: false,
          sticky: false,
          toastType: ToastType.ERROR
        };
      },
    });
  }

  verifyMobileNo() {
    this.loginService.userDetails.sevaType = this.registerForm?.value?.sevaType;
    if (this.isLoginFlow) {
      this.sendOtpToLogin();
    } else {
      this.sendOtpToRegister();
    }
  }
}
