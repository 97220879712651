<div class="mobile-registration-container">
  <div class="header-content">
    <div><img src="assets/i-am-interested/images/kalasha.webp" alt="" class="logo cursor-pointer" (click)="routeToHome()"></div>
    <div><img src="assets/home-page/rightside cta.svg" alt="" (click)="toggleLanguage()" class="translate-icon"></div>
  </div>
  <div class="main-container">
    <div class="flex justify-content-center">
      <img
        src="assets/raamaa.webp"
        class="rama-logo"
        alt=""
      />
    </div>
    <div
      [formGroup]="registerForm"
      class="registration-container flex-column justify-content-around"
    >
      <div class="title">{{ heading | translate}}</div>
      <!-- <div class="radio-btn-div" *ngIf = "!isAdmin">
        <p-radioButton
          class="radio-btn"
          name="sevaType"
          [value]="sevaType.KALASHOTSAVA"
          formControlName="sevaType"
          label="{{'LABEL.kalashotsava'| translate}}"
        >
        </p-radioButton>
        <p-radioButton
          class="radio-btn"
          name="sevaType"
          [value]="sevaType.OTHER_SEVA"
          formControlName="sevaType"
          label="{{'LABEL.otherVoluntaryServices' | translate}}"
        >
        </p-radioButton>
      </div> -->
      <div>
        <div class="flex flex-column form-field ">
          <div class="country-code-input flex gap-2">
          <p-dropdown class="contry-code" [ngClass]="{
              'is-invalid-state': isFormControlValid('country')
            }" [options]="countries" formControlName="countryCode" optionLabel="code" [appendTo]="'body'"></p-dropdown>

          <input class="flex-1" [ngClass]="{
              'is-invalid-input': isFormControlValid('mobileNumber')
            }" appNumberOnly type="tel" maxlength="10" formControlName="mobileNumber" pInputText placeholder="Enter Mobile Number" (keyup.enter)="verifyMobileNo()"
              />
          </div>
          <div class="error-msg flex align-items-center justify-content-center"><span *ngIf="isFormControlValid('mobileNumber')">Please enter a valid mobile
                  number</span></div>
                  <div class="otp-sent-text">{{'TEXT.otpSentToMobile' | translate}}</div>
      </div>
      </div>
      <div  *ngIf="isLoginFlow">
        <p-button class="btn-primary" [disabled]="registerForm.invalid" (click)="verifyMobileNo()">{{'BUTTON.getOtp' | translate}}</p-button>
      </div>
      <div  *ngIf="!isLoginFlow">
        <p-button class="btn-primary" [disabled]="registerForm.invalid" (click)="verifyMobileNo()">{{'BUTTON.register' | translate}}</p-button>
      </div>
    </div>
  </div>
</div>
<app-toaster [toastDetails]="toastDetails"></app-toaster>
<full-page-loader *ngIf="isApiProcessing" [pageSpinner]="isApiProcessing"></full-page-loader>
