import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FullPageLoaderComponent } from './components/full-page-loader/full-page-loader.component';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { MobileRegistrationComponent } from './components/mobile-registration/mobile-registration.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { BlockUIModule } from 'primeng/blockui';
import { TextOnlyDirective } from './directives/text-only.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { ToasterComponent } from './components/toaster/toaster.component';
import { TranslationModule } from '../translation/translation.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { PrefixInputDirective } from './directives/prefix-input.directive';
import { SelectDateComponent } from './components/select-date/select-date.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { ChipModule } from 'primeng/chip';
import { MenuModule } from 'primeng/menu';
import { ImageModule } from 'primeng/image';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { PaginatorModule } from 'primeng/paginator';
import { AmountPipe } from './pipes/amount/amount.pipe';
import { FileImageViewerComponent } from './components/file-image-viewer/file-image-viewer.component';
import { CardModule } from 'primeng/card';
import { CategoryPipe } from './pipes/amount/categoryPipe.pipe';


const RequiredModules = [
  InputTextModule,
  ButtonModule,
  ReactiveFormsModule,
  FormsModule,
  RadioButtonModule,
  InputNumberModule,
  RippleModule,
  ProgressSpinnerModule,
  BlockUIModule,
  DropdownModule,
  ToastModule,
  DynamicDialogModule,
  TableModule,
  CalendarModule,
  InputTextareaModule,
  FileUploadModule,
  ChipModule,
  MenuModule,
  ImageModule,
  PaginatorModule,
  CardModule
];

const SharedComponents = [
  HeaderComponent,
  MobileRegistrationComponent,
  VerifyOtpComponent,
  SideMenuComponent,
  SelectDateComponent,
  FullPageLoaderComponent,
  TextOnlyDirective,
  NumberOnlyDirective,
  PrefixInputDirective,
  ToasterComponent,
  SuccessModalComponent,
  ConfirmationModalComponent
];


const sharedPipes=[
  AmountPipe,
  CategoryPipe
]
@NgModule({
  declarations: [
    ...SharedComponents,
    ...sharedPipes,
    FullPageLoaderComponent,
    TextOnlyDirective,
    NumberOnlyDirective,
    PrefixInputDirective,
    ToasterComponent,
    PrefixInputDirective,
    FileImageViewerComponent,
  ],
  imports: [CommonModule, TranslationModule, ...RequiredModules],
  exports: [
    TranslationModule,
    FullPageLoaderComponent,
    TextOnlyDirective,
    NumberOnlyDirective,
    PrefixInputDirective,
    ToasterComponent,
    ...RequiredModules,
    ...SharedComponents,
    ...sharedPipes,
    
  ],
  providers: [DialogService, MessageService,DatePipe],
})
export class SharedModule {}
