import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum Languages {
  en = 'en',
  hi = 'hi',
}
@Injectable({
  providedIn: 'root'
})

export class CommonServiceService {

  BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  private toastSubject = new BehaviorSubject<Message>({})
  toastObservable = this.toastSubject.asObservable()
  selectedLanguage: string = 'en';

  setLanguage(newLang: string) {
    this.selectedLanguage = newLang;
  }

  states = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  countries = [
    { name: 'India', code: '+91' },
    { name: 'US', code: '+1' },
    { name: 'Australia', code: '+61' },
    { name: 'UAE', code: '+971' },
    { name: 'France', code: '+33' },
    { name: 'Germany', code: '+49' },
    { name: 'Italy', code: '+39' },
    { name: 'UK', code: '+44' },
    { name: 'Canada', code: '+1' },
  ]

  showToastMessage(
    summary: string,
    severity = "SUCCESS",
    btnText = 'OK',
    key = 'default'
  ) {
    this.toastSubject.next({
      severity,
      summary,
      detail: btnText,
      key,
      life: 3000,
    })
  }

  onFilterChanges = (obj: any, filters: any) => {
    let valid,
      filterData = JSON.parse(JSON.stringify(obj));

    function filterAnd(key: any, values: any, data: any) {
      return data.filter(function (d: any) {
        valid = false;
        // for filtering multi select
        if (Array.isArray(values)) {
          for (let v = 0; v < values.length; v++) {
            if (Array.isArray(d[key])) {
              d[key].forEach((el: any) => {
                if (el.name.match(values[v])) {
                  valid = true;
                }
              });
            } else {
              if (d[key].toLowerCase() == values[v].toLowerCase()) {
                valid = true;
              }
            }
          }
        } else {
          // for filtering input search
          if (d[key].toLowerCase().match(values.toLowerCase())) {
            valid = true;
          }
        }
        return valid;
      });
    }

    Object.keys(filters).forEach((f) => {
      filterData = filterAnd(f, filters[f], filterData);
    });

    return filterData;
  };

  fetchInitiatedDocument(params: any) {
    const headers = new HttpHeaders({ 'isAccessTokenRequired': 'false' });
    const apiUrl = this.BASE_URL + '/api/ramarajya/donors-detail';
    return this.http.get<any>(apiUrl, { params, headers });
  }

}
