<div class="select-date">
  <div class="document-container" [ngStyle]="{'padding':isAdmin?'0': '40px'}" *ngIf="availableDates">
    <div class="header" *ngIf="!isAdmin">{{'HEADING.availableDateForSankalpaPrasadam' | translate}}</div>
    <div class="header" *ngIf="isAdmin">{{'HEADING.availableDateForKalasaSeva' | translate}}</div>
    <div class="calender-container">
      <div class="date-picker" *ngFor="let month of monthDate">
        <div class="month">{{month.monthName}}</div>
        <div class="date-container">

          <div class="week" *ngFor="let week of weeks">{{week}}</div>
          <div class="date selected" [ngClass]="{'selected':isSelected(date),'disabled':isDisabled(date)}"
            (click)="selectDate(date)" *ngFor="let date of month.dates">
            {{date.value}}
            <div *ngIf="isAdmin" class="count"
              [ngClass]="{'not-booked-count':isNotBooked(date),'booked-count':isBlocked(date)}"> {{count(date)}}</div>
            <div [ngClass]="{'not-booked-marker':isNotBooked(date),'booked-marker':isBlocked(date)}"
              class="availability-marker"></div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isAdmin&&selectedDateValue?.date">
      <div class="available-text">{{'LABEL.availableSlotsOn' | translate}} <span class="header">{{selectedDate(selectedDateValue?.date)}}</span></div>
      <div class="available-time">8.00 AM</div>
    </ng-container>
    <ng-container *ngIf="isAdmin && selectedDateValue?.date && applicantDetails.length">
      <div class="available-text">{{'LABEL.applicantDetailsScheduledOn' | translate}} <span
          class="header">{{selectedDate(selectedDateValue?.date)}}</span> <span class="reg-text">{{applicantDetails.length}}
            {{'LABEL.registrations' | translate}}</span>
      </div>
      <div class="flex flex-wrap overflow-auto" [ngStyle]="{'max-height': '50vh'}">
        <ng-container *ngFor="let applicant of applicantDetails">
          <div class="applicants-container" *ngIf="applicant.name">
            <div class="name">{{applicant.name}}</div>
            <div>{{applicant.mobileNumber}}</div>
            <div>{{applicant.state}}, {{applicant.country}}</div>
          </div>
        </ng-container>
      </div>
    </ng-container>


    <div *ngIf="!isAdmin" class="footer-btn">
      <p-button class="btn-secondary uppercase" (click)="cancel()">{{'BUTTON.cancel' | translate}}</p-button>
      <p-button class="btn-primary uppercase" (click)="selectSlot()" [disabled]="!selectedDateValue?.date">{{'BUTTON.confirm' | translate}}</p-button>
    </div>

    <div *ngIf="isAdmin" class="footer-btn-admin">
      <p-button class="btn-secondary" (click)="unblock()"
        [disabled]="!selectedDateValue?.date || (selectedDateValue ? !selectedDateValue.isBlocked:false)">{{'BUTTON.unblock'
        | translate}}</p-button>
      <p-button class="btn-primary" (click)="block()"
        [disabled]="!selectedDateValue?.date || (selectedDateValue ? selectedDateValue.isBlocked:false)">{{'BUTTON.block' |
        translate}}</p-button>
    </div>

  </div>
</div>
<app-toaster [toastDetails]="toastDetails"></app-toaster>
<full-page-loader *ngIf="isApiProcessing" [pageSpinner]="isApiProcessing"></full-page-loader>