<div class="otp-container">
  <div class="header-content">
    <div><img src="assets/i-am-interested/images/kalasha.webp" alt="" class="logo cursor-pointer" (click)="routeToHome()"></div>
    <div><img src="assets/home-page/rightside cta.svg" alt="" (click)="toggleLanguage()" class="translate-icon"></div>
  </div>
    <div class="main-container">
        <div class="flex justify-content-center">
          <img
            src="assets/raamaa.webp"
            class="rama-logo"
            alt=""
          />
        </div>
<div class="otp-verification">
    <div class="title">{{'HEADING.otpVerification' | translate}}</div>

    <form [formGroup]="form">
        <div class="flex flex-row justify-content-center">
            <div class="otp-input-group form-field">
                <input  appNumberOnly *ngFor="let input of formInput;  index as i" [ngClass]="{
                    'is-invalid-input': isFormControlValid(input)
                  }"   #formRow pInputText maxlength="1" type="tel"
                    formControlName="{{input}}" class="otp-input" (keyup)="keyUpEvent($event, i)">
            </div>
        </div>
    </form>
    <div class="text-content">
         {{'TEXT.enterCodeViaSms' | translate}}{{maskedMobileNo}}
    </div>
    <div class="btn-group">
        <div class="resend-btn" (click)="resendOtp()" [ngStyle]="{
                    opacity: isDisableResendOTP ? '0.6' : '1',
                    cursor: isDisableResendOTP ? 'not-allowed' : 'pointer'
                  }">
                  {{'BUTTON.resend' | translate}}
        </div>
        <p *ngIf="isDisableResendOTP" class="resend-time-indicator">
            You can Resend OTP in <span>{{ resendTimeLeft }} seconds</span>
        </p>
        <p-button class="btn-primary" (click)="submitOtp()" [disabled]="form.invalid">{{'BUTTON.submit' | translate}}</p-button>
    </div>
</div>
</div>
</div>
<app-toaster [toastDetails]="toastDetails"></app-toaster>
<full-page-loader *ngIf="isApiProcessing" [pageSpinner]="isApiProcessing"></full-page-loader>
