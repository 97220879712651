<app-policies-header></app-policies-header>
<div class="terms-of-use">
  <div class="main-container">
    <div class="heading">Terms Of Use</div>
    <div class="sub-heading">INTRODUCTION</div>
    <div class="content">
      <p>
        The domain address <span><a target="_blank" href="https://ramarajya.co.in">Ramarajya.co.in</a></span> (“Site”)
        is owned by <span class="bold-text">Pejavar Mutt</span>.
        (“Trust”)(“Trust”, “we”, “our”, “us”) <br><br>These Terms of Service
        (“Terms”, “Terms of Service” “Terms of Use“) govern your use of our web
        pages located at <a target="_blank" href="https://ramarajya.co.in">https://ramarajya.co.in</a> operated by <span
          class="bold-text">Pejavar Mutt Trust</span>.
        The term “ you”, “your” shall mean the user of the Site.<br><br> Our
        Privacy Policy also governs your use of our Site and explains how we
        collect, safeguard and disclose information that results from your use
        of our web pages. Please read it here <a target="_blank"
          href="https://ramarajya.co.in/privacy-policy">https://ramarajya.co.in/privacy-policy</a>
        .<br><br>

        Our Trademark Use Policy is available at <a target="_blank"
          href="https://ramarajya.co.in/trademark">https://ramarajya.co.in/trademark</a>.
        <br><br>
        Your agreement with us includes these Terms, Trademark Use Policy and our Privacy Policy (“Agreements”). You
        acknowledge that you have read and understood Agreements and agree to be bound of them.<br><br>

        If you do not agree with (or cannot comply with) Agreements, then you may not use our Site, but please let us
        know by emailing at <span><a target="_blank"
            href="mailto:subrahmanya736@gmail.com">subrahmanya736@gmail.com</a></span> so we can try to find a solution.
        These Terms apply to all visitors, users and others who wish to access or use our Site.
      </p>
    </div>
    <div class="sub-heading">COMMUNICATIONS</div>
    <div class="content">
      By registering in our Site, you agree to subscribe to newsletters,
      marketing or promotional materials and other information we may send.
      However, you may opt out of receiving any, or all, of these communications
      from us by following the unsubscribe link or by emailing at <span><a target="_blank"
          href="mailto:subrahmanya736@gmail.com">subrahmanya736@gmail.com</a></span>
    </div>

    <div class="sub-heading">CONTENT</div>
    <div class="content">
      Content found in this Site are the property of Trust or used with
      permission. You may not distribute, modify, transmit, reuse, download,
      repost, copy, or use said Content, whether in whole or in part, for
      commercial purposes or for personal gain, without express advance written
      permission from us.
    </div>

    <div class="sub-heading">PROHIBITED USES</div>
    <div class="content">
      You may use our Site only for lawful purposes and in accordance with
      Terms. You agree not to use our Site:
      <li>
        In any way that violates any applicable national or international law or
        regulation.
      </li>
      <li>
        For the purpose of exploiting, harming, or attempting to exploit or harm
        anyone in any way by exposing them to inappropriate content or
        otherwise.
      </li>
      <li>
        To transmit, or procure the sending of, any advertising or promotional
        material, including any “junk mail”, “chain letter,” “spam,” or any
        other similar solicitation.
      </li>
      <li>
        To impersonate or attempt to impersonate Trust, a Trust employee,
        another user, or any other person or entity.
      </li>
      <li>
        In any way that infringes upon the rights of others, or in any way is
        illegal, threatening, fraudulent, or harmful, or in connection with any
        unlawful, illegal, fraudulent, or harmful purpose or activity.
      </li>
      <li>
        To engage in any other conduct that restricts or inhibits anyone’s use
        or enjoyment of Site, or which, as determined by us, may harm or offend
        Trust or users of Site or expose them to liability.
      </li>
      Additionally, you agree not to:
      <li>
        Use Site in any manner that could disable, overburden, damage, or impair
        Site or interfere with any other party’s use of Site, including their
        ability to engage in real time activities through Site.
      </li>
      <li>
        Use any robot, spider, or other automatic device, process, or means to
        access Site for any purpose, including monitoring or copying any of the
        material on Site.
      </li>
      <li>
        Use any manual process to monitor or copy any of the material on Site or
        for any other unauthorized purpose without our prior written consent.
      </li>
      <li>
        Use any device, software, or routine that interferes with the proper
        working of Site.
      </li>
      <li>
        Introduce any viruses, trojan horses, worms, logic bombs, or other
        material which is malicious or technologically harmful.
      </li>
      <li>
        Attempt to gain unauthorized access to, interfere with, damage, or
        disrupt any parts of Site, the server on which Site is stored, or any
        server, computer, or database connected to Site.
      </li>
      <li>Take any action that may damage or falsify Trust rating.</li>
      <li>Otherwise attempt to interfere with the proper working of Site.</li>
    </div>

    <div class="sub-heading">NO USE BY MINORS</div>
    <div class="content">
      Site is intended only for access and use by individuals at least eighteen
      (18) years old. By accessing or using any of Trust, you warrant and
      represent that you are at least eighteen (18) years of age and with the
      full authority, right, and capacity to enter into this Agreement and abide
      by all of the terms and conditions of Terms. If you are not at least
      eighteen (18) years old, you are prohibited from both the access and usage
      of Site.
    </div>

    <div class="sub-heading">ACCOUNTS</div>
    <div class="content">
      When you create an account with us, you guarantee that you are above the
      age of 18, and that the information you provide us is accurate, complete,
      and current at all times. Inaccurate, incomplete, or obsolete information
      may result in the immediate termination of your account on Site. You are
      responsible for maintaining the confidentiality of your account and
      password, including but not limited to the restriction of access to your
      computer and/or account. You agree to accept responsibility for any and
      all activities or actions that occur under your account and/or password,
      whether your password is with our Site or a third-party website. You must
      notify us immediately upon becoming aware of any breach of security or
      unauthorized use of your account. You may not use as a username the name
      of another person or entity or that is not lawfully available for use, a
      name or trademark that is subject to any rights of another person or
      entity other than you, without appropriate authorization. You may not use
      as a username any name that is offensive, vulgar or obscene. In such
      cases, we reserve the right to refuse service, terminate accounts, remove
      or edit content, or cancel orders in our sole discretion.
    </div>

    <div class="sub-heading">ERROR REORTING AND FEEDBACK</div>
    <div class="content">
      You may provide us directly at <span> <a target="_blank"
          href="mailto:subrahmanya736@gmail.com">subrahmanya736@gmail.com</a></span> with information and
      feedback concerning errors, suggestions for improvements, ideas, problems,
      complaints, and other matters related to our Site (“Feedback”). You
      acknowledge and agree that: (i) you shall not retain, acquire or assert
      any intellectual property right or other right, title or interest in or to
      the Feedback; (ii) Trust may have development ideas similar to the
      Feedback; (iii) Feedback does not contain confidential information or
      proprietary information from you or any third party; and (iv) Trust is not
      under any obligation of confidentiality with respect to the Feedback. In
      the event the transfer of the ownership to the Feedback is not possible
      due to applicable mandatory laws, you grant Trust and its affiliates an
      exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
      unlimited and perpetual right to use (including copy, modify, create
      derivative works, publish, distribute and commercialize) Feedback in any
      manner and for any purpose.
    </div>

    <!-- <div class="sub-heading">LINKS TO EXTERNAL WEBSITES</div>
    <div class="content">
      Our Site may contain links to third party websites or services that are
      not owned or controlled by Trust. The Site provides links to websites and
      access to content, products and services from third parties, including
      users, advertisers, affiliates and sponsors of the Site. The Trust has no
      control over, and assumes no responsibility for the content, privacy
      policies or practices of any third-party web sites or services or payments
      made by you to any third-party services through the links available in
      their website. We do not warrant the offerings of any of these
      entities/individuals or their websites. The Trust is not responsible for
      any refund to you from any third-party links available in the Site. YOU
      ACKNOWLEDGE AND AGREE THAT THE TRUST SHALL NOT BE RESPONSIBLE OR LIABLE,
      DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE
      CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
      GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD-PARTY WEB SITES
      OR SERVICES. WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
      PRIVACY POLICIES OF ANY THIRD-PARTY WEB SITES OR SERVICES THAT YOU VISIT.
    </div> -->

    <div class="sub-heading">DISCLAIMER OF WARRANTY</div>
    <div class="content">
      THIS SITE IS PROVIDED BY TRUST ON AN “AS IS” AND “AS AVAILABLE” BASIS. TRUST MAKES NO REPRESENTATIONS OR
      WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SITE, OR THE INFORMATION, CONTENT OR
      MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SITES, THE CONTENT, AND ANY SERVICES OR
      ITEMS OBTAINED FROM ANY THIRD-PARTY WEB SITES IS AT YOUR SOLE RISK. NEITHER TRUST NOR ANY PERSON ASSOCIATED WITH
      TRUST MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
      ACCURACY, OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER TRUST NOR ANYONE ASSOCIATED WITH
      TRUST REPRESENTS OR WARRANTS THAT THE SITE OR ITS CONTENT WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
      UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
      VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE CONTENT IN THE SITE WILL OTHERWISE MEET YOUR NEEDS OR
      EXPECTATIONS. TRUST HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
      OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
      PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
      APPLICABLE LAW.
    </div>

    <div class="sub-heading">LIMITATION OF LIABILITY</div>
    <div class="content">
      EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
      EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL,
      INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING
      ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND
      ARBITRATION), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR ARISING OUT
      OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY
      CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT
      AND ANY VIOLATION BY YOU OF ANY STATE OR LOCAL LAWS, STATUTES, RULES, OR
      REGULATIONS, EVEN IF TRUST HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY
      OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND
      ON THE PART OF TRUST, IT WILL BE LIMITED TO THE AMOUNT, IF ANY, PAID FOR
      THE RELATED DIRECT PRODUCTS AND/OR DIRECT SERVICES UNDER WHICH SUCH
      LIABILTY ARISES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
      PUNITIVE DAMAGES.
    </div>

    <div class="sub-heading">TERMINATION</div>
    <div class="content">
      We may terminate or suspend your account and bar access to Site
      immediately, without prior notice or liability, under our sole discretion,
      for any reason whatsoever and without limitation, including but not
      limited to a breach of Terms. If you wish to terminate your account, you
      may simply discontinue using Site. All provisions of Terms which by their
      nature should survive termination shall survive termination, including,
      without limitation, ownership provisions, warranty disclaimers, indemnity
      and limitations of liability.
    </div>

    <div class="sub-heading">GOVERNING LAW</div>
    <div class="content">
      These Terms shall be governed and construed in accordance with the laws of
      Udupi, Karnataka State, India without regard to its conflict of law
      provisions. Our failure to enforce any right or provision of these Terms
      will not be considered a waiver of those rights. If any provision of these
      Terms is held to be invalid or unenforceable by a court, the remaining
      provisions of these Terms will remain in effect. These Terms constitute
      the entire agreement between us regarding our Site and supersede and
      replace any prior agreements we might have had between us regarding Site.
    </div>

    <div class="sub-heading">CHANGES TO SITE</div>
    <div class="content">
      We reserve the right to withdraw or amend our Site, and any service or
      material we provide via Site, in our sole discretion without notice. We
      will not be liable if for any reason all or any part of Site is
      unavailable at any time or for any period. From time to time, we may
      restrict access to some parts of Site, or the entire Site, to users,
      including registered users.
    </div>

    <div class="sub-heading">AMENDMENTS TO TERMS</div>
    <div class="content">
      We may amend Terms at any time by posting the amended terms on this Site.
      You are expected to check this page frequently, so you are aware of any
      changes, as they are binding on you. By continuing to access or use our
      Site after any revisions become effective, you agree to be bound by the
      revised terms. If you do not agree to the new terms, you are no longer
      authorized to use Site.
    </div>

    <div class="sub-heading">WAIVER AND SEVERABILITY</div>
    <div class="content">
      No waiver by Trust of any term or condition set forth in Terms shall be
      deemed a further or continuing waiver of such term or condition or a
      waiver of any other term or condition, and any failure of Trust to assert
      a right or provision under Terms shall not constitute a waiver of such
      right or provision. If any provision of Terms is held by a court or other
      tribunal of competent jurisdiction to be invalid, illegal or unenforceable
      for any reason, such provision shall be eliminated or limited to the
      minimum extent such that the remaining provisions of Terms will continue
      in full force and effect.
    </div>

    <div class="sub-heading">ACKNOWLEDGEMENT</div>
    <div class="content">
      BY USING OUR SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
      SERVICE AND AGREE TO BE BOUND BY THEM.
    </div>

    <div class="sub-heading">CONTACT US</div>
    <div class="content">
      Please send your feedback, comments, requests for technical support: By
      email: <span> <a target="_blank" href="mailto:subrahmanya736@gmail.com">subrahmanya736@gmail.com</a></span>
    </div>
  </div>
</div>
<app-policies-footer></app-policies-footer>