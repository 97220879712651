import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface SideMenu{
  name:MenuName,
  status:MenuStatus
  }
  export enum MenuName{
  UPLOAD_DOCUMENT='LABEL.uploadDoc',
  AVAILABLE_SLOTS='LABEL.availableSlots',
  PAYMENT='LABEL.payment',
  APPLICATION = 'LABEL.application',
  PAYMENT_STATUS = 'Booking Status',
  RESERVE_SLOT = 'Reserve the slots',
  OTHER_VOLUNTARY_SERVICE = 'Other Voluntary Services',
  BYPASS_LOGIN = 'Whitelist Mobile No\'s'
  }

  export enum MenuStatus{
    ACTIVE='active',
    INACTIVE='inactive',
    COMPLETED='completed',
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    SELECTABLE = 'selectable'
  }

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  private sideMenus = new BehaviorSubject<SideMenu[]|null>(null)
  sideMenusObservable = this.sideMenus.asObservable()
  
  private selectedSideMenu = new BehaviorSubject<string>('');
  selectedSideMenuObs = this.selectedSideMenu.asObservable();

  constructor() { }
  

  setSideMenus(sideMenus: SideMenu[]) {
    this.sideMenus.next(sideMenus)
  }

  get getSideMenus() {
    return this.sideMenusObservable
  }

  setSelectedSideMenu(inMenu: string){
    this.selectedSideMenu.next(inMenu);
  }

}
