import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  BASE_URL = environment.apiUrl;
  API_URL = this.BASE_URL + "/api/ramarajya/applications";

  constructor(private http: HttpClient) {}

  fetchAllApplications() {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl = this.API_URL + "/fetch-all?applicationStatus";
    return this.http.get<any>(apiUrl, { headers });
  }

  fetchAllDocuments(applicationId: number) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl =
      this.API_URL + "/view-all-documents?applicationId=" + applicationId;
    return this.http.get<any>(apiUrl, { headers });
  }

  rejectDocument(data: any) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl = this.API_URL + "/rejection?actionLevel=DOCUMENT";
    return this.http.put<any>(apiUrl, data, { headers });
  }

  approveDocument(documentId: number) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl =
      this.API_URL + "/approval?actionLevel=DOCUMENT&id=" + documentId;
    return this.http.put<any>(apiUrl, { headers });
  }

  approveApplication(applicationId: number) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl =
      this.API_URL + "/approval?actionLevel=APPLICATION&id=" + applicationId;
    return this.http.put<any>(apiUrl, { headers });
  }

  rejectApplication(data: any) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl = this.API_URL + "/rejection?actionLevel=APPLICATION";
    return this.http.put<any>(apiUrl, data, { headers });
  }

  fetchApplicantDetails(data: any) {
    const apiUrl = this.BASE_URL + "/api/ramarajya/slot-user-details";
    return this.http.post<any>(apiUrl, data);
  }

  blockSlot(data: any) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl = this.BASE_URL + "/api/ramarajya/block-slot";
    return this.http.post<any>(apiUrl, data, { headers });
  }

  unBlockSlot(data: any) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl = this.BASE_URL + "/api/ramarajya/unblock-slot";
    return this.http.post<any>(apiUrl, data, { headers });
  }

  fetchAllVoluntaryServices() {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl =
      this.BASE_URL +
      "/api/ramarajya/other-voluntary-service/fetch-all";
    return this.http.get<any>(apiUrl, { headers });
  }

  fetchAllVoluntaryById(id: any) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const apiUrl =
      this.BASE_URL +
      "/api/ramarajya/other-voluntary-service/fetch?voluntaryServiceId=" +
      id;
    return this.http.get<any>(apiUrl, { headers });
  }

  exportReport(statusList:string[]) {
    const apiUrl = this.API_URL + "/export";
    return this.http.get<any>(apiUrl, { params: {statusList}, responseType: 'arraybuffer' as 'json'  });
  }

  exportBookingDetails() {
    const apiUrl = this.BASE_URL + "/api/ramarajya/booking-details-export";
    return this.http.get<any>(apiUrl, { responseType: 'arraybuffer' as 'json'  });
  }
  getBookingDetails() {
    const apiUrl = this.BASE_URL + "/api/ramarajya/booking-details";
    return this.http.get<any>(apiUrl);
  }
  getWhitelistedMobNo() {
    const apiUrl = this.BASE_URL + "/api/ramarajya/auth-whitelist/whitelisted-numbers";
    return this.http.get<any>(apiUrl);
  }
  deleteWhitelistedMobNo(id:number) {
    const apiUrl = this.BASE_URL + "/api/ramarajya/auth-whitelist/remove?id="+id;
    return this.http.delete<any>(apiUrl);
  }
  whitelistMobNo(data:{}) {
    const apiUrl = this.BASE_URL + "/api/ramarajya/auth-whitelist/new";
    return this.http.post<any>(apiUrl, data);
  }
}
