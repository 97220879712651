<div class="side-menu">

    <div class="menu" *ngFor="let menu of sideMenus" [ngClass]="getMenuClass(menu)" (click)="onMenuClick(menu.name)">
        <div class="flex flex-row">
            <div class="menu-name" [ngClass]="{'admin-menu-name': pageType === 'admin'}" title="{{menu.name}}">
                {{menu.name | translate}}</div>
            <div class="image-container-mbl" *ngIf="isComplete(menu.status)">
                <img class="success-image" src="assets/kalashotsava/success-check.svg" alt="">
            </div>
        </div>
        <div *ngIf="menu.status === 'active'" class="is-active-mobile"></div>
        <div class="image-container" *ngIf="isComplete(menu.status)">
            <img class="success-image" src="assets/kalashotsava/success-check.svg" alt="">
        </div>
    </div>

</div>