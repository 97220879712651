import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileRegistrationComponent } from '../shared/components/mobile-registration/mobile-registration.component';
import { VerifyOtpComponent } from '../shared/components/verify-otp/verify-otp.component';
import { RegitrationFormComponent } from './components/regitration-form/regitration-form.component';
import { InstructionGuidlinesComponent } from './pages/instruction-guidlines/instruction-guidlines.component';
import { MainHomePageComponent } from './pages/main-home-page/main-home-page.component';

const routes: Routes = [
    {
      path: '',
      component: MainHomePageComponent, 
      pathMatch: 'full',
    },
    {
      path: 'home',
      component: MainHomePageComponent, 
    },
    {
      path: 'instructions-guidelines',
      component: InstructionGuidlinesComponent, 
    },
    {
      path: 'login',
     component: MobileRegistrationComponent, 
    },
    {
      path: 'register',
     component: MobileRegistrationComponent, 
    },
    {
      path: 'verify-otp',
     component: VerifyOtpComponent, 
    },
    {
      path: 'registration-form',
     component: RegitrationFormComponent, 
    },
   
  ]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule { }
