<div class="main-container">
    <div>
        <app-header></app-header>
    </div>
    <div class="flex justify-content-center align-items-center">
        <div class="user-details-entry">
            <div class="title">{{'HEADING.fillYourInformation' | translate}}</div>
            <form class="container" [formGroup]="userRegistrationForm">

                <div class="flex justify-content-center">
                    <div  (click)="imageFile.click()">
                        <div *ngIf="!imgUrl" class="browse-img">
                          <img src="assets/home-page/profile.svg" alt="">
                        </div>
                        <img [src]="imgUrl" *ngIf="imgUrl" class="flex flex-1 image-file">
                      </div>
                      <input type='file' accept="image/png, image/gif, image/jpeg" id="imageFile"  class="hidden" #imageFile  (change)="onSelectFile($event,'image')" />
                </div>

                <div class="flex flex-column form-field">
                    <label>{{'TEXT.name' | translate}}*</label>
                    <input [ngClass]="{
                        'is-invalid-input': isFormControlValid('name')
                      }" appTextOnly formControlName="name" pInputText placeholder="{{'TEXT.enterName' | translate}}" />
                    <div class="error-msg"><span *ngIf="isFormControlValid('name')">{{'TEXT.enterGivenToName' | translate}}</span></div>
                </div>
                <div class="flex flex-column form-field ">
                    <label>{{'LABEL.mobileNumber' | translate}}*</label>
                    <div class="country-code-input gap-2">
                    <p-dropdown class="contry-code" [ngClass]="{
                        'is-invalid-state': isFormControlValid('country')
                      }" [options]="countries" formControlName="countryCode" optionLabel="code" [appendTo]="'body'"></p-dropdown>
        
                    <input class="flex-1 mbl-no" [ngClass]="{
                        'is-invalid-input': isFormControlValid('mobileNo')
                      }" appNumberOnly type="tel" formControlName="mobileNumber" pInputText placeholder="{{'LABEL.enterMobileNo' | translate}}"
                        />
                    </div>
                    <div class="error-msg"><span *ngIf="isFormControlValid('mobileNo')">{{'TEXT.enterValidMobileNo' | translate}}
                            </span></div>
                </div>
                <div class="flex flex-column form-field">
                    <label>{{'LABEL.emailId' | translate}}</label>
                    <input [ngClass]="{
                        'is-invalid-input': isFormControlValid('email')
                      }" formControlName="email" pInputText placeholder="{{'TEXT.enterEmailId' | translate}}" />
                      <div class="error-msg"><span *ngIf="isFormControlValid('email')">{{'TEXT.enterValidEmailId' | translate}}</span></div>
                    </div>
                    <div class="flex flex-column form-field">
                        <label>{{'LABEL.address' | translate}}*</label>
                        <input [ngClass]="{
                            'is-invalid-input': isFormControlValid('address')
                          }" formControlName="address" pInputText placeholder="{{'TEXT.enterAddress' | translate}}" />
                        <div class="error-msg"><span *ngIf="isFormControlValid('address')">{{'TEXT.enterValidAddress' | translate}}</span></div>
                    </div>
                    <div class="flex flex-column form-field">
                        <label>{{'LABEL.countryRegion' | translate}}</label>
                        <p-dropdown [ngClass]="{
                            'is-invalid-state': isFormControlValid('country')
                          }" [options]="countries" formControlName="country" optionLabel="name" [appendTo]="'body'"></p-dropdown>
                    </div>
                    <div *ngIf="userRegistrationForm.get('state')" class="flex flex-column form-field">
                        <label>{{'LABEL.state' | translate}}*</label>
                        <p-dropdown [ngClass]="{
                            'is-invalid-state': isFormControlValid('state')
                          }" [options]="states | keyvalue" formControlName="state" optionLabel="value" optionValue="value" [autoDisplayFirst]="false"  placeholder="{{'LABEL.selectState' | translate}}"></p-dropdown>
                          <div class="error-msg"><span *ngIf="isFormControlValid('state')">{{'TEXT.enterValidState' | translate}}</span></div>
                    </div>
                    <div class="flex flex-column form-field">
                        <label>{{'LABEL.pinCodeAreaCode' | translate}}*</label>
                        <input appNumberOnly type="tel" formControlName="pinCode" pInputText placeholder="{{'TEXT.enterPincode' | translate}}"/>
                        <div class="error-msg"><span *ngIf="isFormControlValid('pinCode')">{{'TEXT.enterValidPincode' | translate}}</span></div>
                    </div>
                <div class="btn-group">
                    <p-button class="btn-secondary" (click)="cancel()"> {{'BUTTON.cancel' | translate}}</p-button>
                    <p-button class="btn-primary" (click)="submitForm()">{{'BUTTON.getOtp' | translate}}</p-button>
                </div>
            </form>
        </div>
    </div>
</div>
<full-page-loader *ngIf="isApiProcessing" [pageSpinner]="isApiProcessing"></full-page-loader>
<app-toaster [toastDetails]="toastDetails"></app-toaster>