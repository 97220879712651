import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TimeoutError } from 'rxjs'
import { CommonServiceService } from '../common/common-service.service'
import { LoginService } from '../login/login.service'
import { ResultInfo } from 'src/app/models/common-model'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService  {
  constructor(
    private commonService: CommonServiceService,
    private loginService: LoginService,
    private router: Router,
  ) {}

  handle(error: Error | HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return this.openDialog(`No connection to server.`)
    }

    if (
      error instanceof HttpErrorResponse &&
      error.error &&
      error.error.resultInfo &&
      error.status !== 401 
    ) {
      return this.handleFailedResponse(error.error.resultInfo)
    }

    if (
      error instanceof HttpErrorResponse &&
      error.error &&
      error.error.message &&
      error.status !== 401     ) {
      return this.openDialog(error.error.message)
    }

    if (error instanceof Error && error.message) {
      return this.openDialog(`An unknown error occurred`)
    }

    this.handleErrorByCode(error)
  }

  handleErrorByCode(error: Error | HttpErrorResponse) {
    // Generic HTTP errors
    if (
      error instanceof HttpErrorResponse 
    ) {
      switch (error.status) {
        case 0:
          this.logout()
          return this.openDialog('Please Login to access your account')
        break
        case 400:
          this.handle400Error(error)
          break

        case 401:
          // if (
          //   error.error?.resultInfo.code ===
          // 'USER_NOT_ALLOWED_TO_ACCESS'
          // ) {
          //   return this.openDialog(error.error?.resultInfo.message)
          // } else {
            this.logout()
            return this.openDialog('Please Login to access your account')
          // }

        case 403:
          return this.openDialog(`You don't have the required permissions`)

        case 404:
          return this.openDialog('Resource not found')

        case 422:
          return this.openDialog('Invalid data provided')

        case 500:
        case 501:
        case 502:
        case 503:
          return this.openDialog('An internal server error occurred')

        case -1:
          return this.openDialog(
            'You appear to be offline. Please check your internet connection and try again.'
          )

        case 0:
          return this.openDialog(`CORS issue?`)

        default:
          return this.openDialog(`An unknown error occurred`)
      }
    }
  }

  handle400Error(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (typeof error.error === 'object') {
        this.openDialog('Record not found')
      } else {
        if (error.error === 'invalid_username_or_password') {
          this.openDialog('Invalid username or password')
        } else {
          this.openDialog('Bad request')
        }
      }
    }
  }

  /**
   * This function will show an error toast message
   * @param res ResultInfo
   */
  handleFailedResponse(res: ResultInfo) {
    this.openDialog(res.message)
  }

  private openDialog(message: string) {
    this.commonService.showToastMessage(message, 'ERROR')
  }

  logout(){
    this.loginService.logout().subscribe(
      (response) => {
        this.router.navigateByUrl("/home");
      },
      (error) => {
        console.error('Logout error', error);
        this.router.navigateByUrl("/home");
      }
    );
  }
}

