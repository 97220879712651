export const AUTH_URL = {
    login:'login',
logout :'/api/ramarajya/auth/logout',
refreshToken: '/api/ramarajya/auth/refresh-token'
}

export const REGISTER_URL = {
    checkRegistration : '/api/ramarajya/checkUserRegistration',
    userLogin:'/api/ramarajya/auth/user-login',
    sendOtpToLogin:'/api/ramarajya/sendOTP',
    registerUserDetails:'/api/ramarajya/saveRegistrationDetails',
    verifyOtpToRegister:'/api/ramarajya/verify-otp',
    verifyOtpToAdminLogin:'/api/ramarajya/auth/admin-login'
}
