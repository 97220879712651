<div class="main-home-page">
  <div class="first-box">
    <div class="background">
      <app-header class="header" [showRegisterLoginBtns]="showRegisterLoginBtns" [showProfile]="true" [showKalash]="false"></app-header>

      <div class="rama-container">
        <div class="rama">
          <img src="assets/raamaa.webp" />
        </div>
      </div>
      <div class="janani-janmabhumischa">
        <span>{{ "MAIN_PAGE_TEXT.raamoVigrahavan" | translate }}</span>
      </div>
      <div class="janani-janmabhumi center-content">
        <span>{{ "MAIN_PAGE_TEXT.header1" | translate }}</span>
      </div>
      <div class="janani-janmabhumi center-content">
        <span>{{ "MAIN_PAGE_TEXT.header2" | translate }}</span>
      </div>
      <div class="come-be-part">{{ "MAIN_PAGE_TEXT.comeBePart" | translate }}</div>

      <div class="brahmakalsha-text center-content">
       <span> {{"MAIN_PAGE_TEXT.centuriesOldWait" | translate }} </span><br>
      </div>
      <div class="first-sec">
        <img src="assets/i-am-interested/images/vishwesha_theertha.png" />
        <p class="swamiji-title">
          {{ "MAIN_PAGE_TEXT.vishwaThirtha" | translate}} <br />
          {{ "MAIN_PAGE_TEXT.pejawarMutt" | translate }}
        </p>
      </div>
    

      <img class="curvy" src="/assets/rangoli.svg" alt="" />
      <div (click)="goToBottom()">
        <img src="assets/home-page/gotoBottom.png" alt="" class="goToBottom" />
      </div>
      <img  src="/assets/home-page/robosoft-logo.svg" alt="" class="robo-logo" />

  </div>
  
  </div>
  <div class="second-box">

    <div class="vision-container">
      <div class="second-row">
        <div *ngIf="donorBeneficiariesCount&&donorBeneficiariesCount.donors" class="count-box flex flex-row">
          <div class="col v-line">
            <div class="count">{{donorBeneficiariesCount.donors}}</div>
            <div class="text">{{"MAIN_PAGE_TEXT.noOfDonors" | translate}}</div>
          </div>
          <div class="col">
            <div class="count">{{donorBeneficiariesCount.beneficiaries}}</div>
            <div class="text">{{"MAIN_PAGE_TEXT.beneficiaries" | translate}}</div>
          </div>
        </div>
        <div class="save-bhakthi flex justify-content-around">
          <p>{{ "MAIN_PAGE_TEXT.ramaSeve" | translate }}</p>
          <p>{{ "MAIN_PAGE_TEXT.ramaBhakti" | translate }}</p>
        </div>
        <p>
          <span class="janani-janma">{{
            "MAIN_PAGE_TEXT.jananiJanmaBhummischa" | translate
            }}</span>
          <span class="poppins-medium"> {{ "MAIN_PAGE_TEXT.saidShriRam" | translate }}</span> <br /><span
            class="poppins-medium">{{
            "MAIN_PAGE_TEXT.amongSeveralQualties" | translate
            }}&nbsp; </span><span class="janani-janma">{{
            "MAIN_PAGE_TEXT.prajaanaamHite" | translate
            }}</span><span class="poppins-medium">{{ "MAIN_PAGE_TEXT.comeJoinUs" | translate }}</span>
        </p>

        <p>
          <span class="janani-janma">{{"MAIN_PAGE_TEXT.riktaHastena" | translate}}</span><br />
          <span class="poppins-medium">{{"MAIN_PAGE_TEXT.holyFeet" | translate}}
             <br />
             {{"MAIN_PAGE_TEXT.shriRamAtJanmabhoomi" | translate}}</span> <br />
        </p>
      </div>
      <div class="second-sec">
        <img src="assets/i-am-interested/images/vishwaprasanna_theertha.webp" />
        <p class="swamiji-title">
          {{ "MAIN_PAGE_TEXT.vishwaPrasanna" | translate }} <br />
          {{ "MAIN_PAGE_TEXT.pejawarMutt" | translate }}
        </p>
      </div>
      <div class="third-sec">
        <div class="title">{{ "TEXT.vision" | translate }}</div>
        <div class="body">
          {{ "MAIN_PAGE_TEXT.ramaSentence" | translate }}
          <span class="rama-sentence">
          {{ "MAIN_PAGE_TEXT.rastraPurush" | translate }}
          </span> {{ "MAIN_PAGE_TEXT.vision" | translate }}
        </div>
        <div class="title">{{ "TEXT.mission" | translate }}</div>

        <div class="body">
          {{ "MAIN_PAGE_TEXT.mission1" | translate }}<br>
        <p class="mission-margin"> {{ "MAIN_PAGE_TEXT.mission2" | translate }}<span class="publish-here">{{ "MAIN_PAGE_TEXT.publishHere" | translate }}</span><br></p> 
          {{ "MAIN_PAGE_TEXT.mission3" | translate }}<br>
          

        </div>
        <div class="offer">
          {{ "MAIN_PAGE_TEXT.offer" | translate }}<br>
          {{ "MAIN_PAGE_TEXT.visitOurPage" | translate }}<span> {{"MAIN_PAGE_TEXT.jaiShreeRam" | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="i-am-interested">
    <img class="i-am-interested-web-img" src="assets/home-page/i-am-interested-web-img.webp" />
    <img class="i-am-interested-mob-img" src="assets/home-page/i-am-interested-mob-img.webp" />
    <div class="content">
    <div class="contribution-title"> {{ "MAIN_PAGE_TEXT.haveYou" | translate }} </div>
    <div class="well-done"> {{ "MAIN_PAGE_TEXT.wellDone" | translate }} </div>
    <div class="i-am-interested-body"> {{ "MAIN_PAGE_TEXT.areUInterested" | translate }} <br>
      {{ "MAIN_PAGE_TEXT.areUInterested1" | translate }}</div>
      <div>
        <p-button
        class="btn-primary"
        (click)="offerKalasha()"
        > {{ "MAIN_PAGE_TEXT.wouldYouBtn" | translate }}</p-button
      >
      </div>
      
  </div>
  </div>
  <!-- <div class="offer-btn">
    <p-button
    class="btn-primary"
    (click)="offerKalasha()"
    > {{ "MAIN_PAGE_TEXT.wouldYouBtn" | translate }}</p-button
  >
  </div> -->
  <ng-container *ngIf="hasDonorList">
  <div class="photo-gallery-container flex flex-column gap-4">
    <div class="photo-gallery">{{ "MAIN_PAGE_TEXT.photoGallery" | translate }}</div>
  </div>

  <div class="flex flex-column md:flex-row flex-wrap search-btn justify-content-between">
    <div class="sm:col-12 md:col-3 flex flex-column form-field justify-content-end">
      <input (change)="fetchPhotoGallery(true)" [(ngModel)]="searchByName" pInputText placeholder="{{ 'TEXT.searchByName' | translate }}" />
     
    </div>
    <div class="sm:col-12 md:col-3 flex flex-column form-field ">
      <div class="flex flex-column form-field">
        <label>{{ 'TEXT.state' | translate }}</label>
        <p-dropdown (onChange)="fetchPhotoGallery(true)" [options]="states | keyvalue" [(ngModel)]="state" optionLabel="value" optionValue="value" [autoDisplayFirst]="false"  placeholder="{{ 'LABEL.selectState' | translate }}"></p-dropdown>
    </div>
    </div>
    </div>

    <div  class="masonry ">
      <div class="grid" *ngFor="let item of photoGallery">
        <div class="image-container">
          <img loading="lazy" [src]="item.documentDetails[0].photosFileUrl" alt="Image 1" class="image" />
          <!-- <div class="plus-more" *ngIf="item.documentDetails.length > 1">
            <div class="count">+{{item.documentDetails.length - 1}}</div>
        </div> -->
         
        </div>
        <div class="flex flex-row" >
          <div class="col flex-grow-0">
            <div class="profile-img">
        <img loading="lazy" *ngIf="item.donorDetail.profilePhotoUrl" [src]="item.donorDetail.profilePhotoUrl" alt="Image 1" class="profile-img" />
            </div>
          </div>
          <div class="col">
            <div class="profile-name">{{item.donorDetail.name}}</div>
            <div class="donation-detail">{{"MAIN_PAGE_TEXT.donationAmountINR" | translate}} {{item.applicationDetail.donationAmount | amount}}</div>
            <div class="donation-detail">{{"LABEL.category" | translate}}: {{item.documentDetails[0].donationCategory | categoryPipe }}  </div>
            <div class="donation-detail">{{"MAIN_PAGE_TEXT.place" | translate}}: {{item.donorDetail.state}}  </div>
          </div>
        </div>

      </div>
    </div>
    <div class="no-rectords" *ngIf="!photoGallery.length">
      No Records found
    </div>

    <button pButton class="view-more uppercase" *ngIf="!isLastPage" (click)="viewMore()">{{"BUTTON.viewMore" | translate}}</button>
  <!-- <div class="image-container">
    <img src="assets/home-page/Frame.webp" alt="" class="mandala-bg" />
  </div> -->
</ng-container>

  <div class="footer">
    <img  src="assets/home-page/Go to top.png" alt="" class="scrollToTop" (click)="scrollToTop()" />
    <div class="robo-logo">
      <a href="https://www.robosoftin.com/" target="_blank">
        <img loading="lazy"  src="assets/robosoftlogo.svg" class="desktop" />
        <img loading="lazy"  src="assets/robosoftlogo_mbl.svg" class="mobile" />
      </a>
    </div>
    <div class="app-stores">
      <div class="info-container">
        <span (click)="termsAndConditions()" class="cursor-pointer">{{
          "MAIN_PAGE_TEXT.terms" | translate
          }}</span>
        <span (click)="privacyPolicy()" class="cursor-pointer">{{
          "MAIN_PAGE_TEXT.privacypolicy" | translate
          }}</span>
        <span (click)="trademarks()" class="cursor-pointer">{{
          "MAIN_PAGE_TEXT.trademarks" | translate
          }}</span>
      </div>
    </div>
  </div>