import { Injectable } from '@angular/core';
import { LocalStorageKeys } from 'src/app/constants/local-storage-keys';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {

   }

   getRefreshToken() {
    return localStorage.getItem(LocalStorageKeys.refreshToken)
  }

  setAccessToken(token: string) {
    localStorage.setItem(LocalStorageKeys.accessToken, token)
  }

  setRefreshToken(token: string) {
    localStorage.setItem(LocalStorageKeys.refreshToken, token)
  }
  
  getAccessToken() {
    return localStorage.getItem(LocalStorageKeys.accessToken)
  }

  getLoggedInUserDetail() {
    const userDetail = localStorage.getItem(LocalStorageKeys.loggedInUserDetail);
    return JSON.parse(userDetail ?? 'null');
  }

  setLoggedInUserDetail(userDetail: string) {
    localStorage.setItem(
      LocalStorageKeys.loggedInUserDetail,
     JSON.stringify(userDetail) 
      )
  }

  removeLoggedInUserDetail() {
    localStorage.removeItem(LocalStorageKeys.loggedInUserDetail)
  }

  removeTokens() {
    localStorage.removeItem(LocalStorageKeys.accessToken)
    localStorage.removeItem(LocalStorageKeys.refreshToken)
  }
}
