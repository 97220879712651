<div class="instruction-guidelines">
    <app-policies-header [isInstructionPage]="true"></app-policies-header>
    <div class="second-box">
       
        <div class="vision-container">
            <div class="back-btn" >
                <img (click)="goToHome()" src="assets/kalashotsava/back-btn.svg" alt="">
                <div (click)="goToHome()">{{ "BUTTON.home" | translate }}</div>
                </div>
            <div class="first-row">

                <p class="sub-content">
                    {{ "MAIN_PAGE_TEXT.agamaShastra" | translate }}
                </p>
                <p class="sub-content">
                    {{ "MAIN_PAGE_TEXT.abhishekas" | translate }}
                </p>
            </div>
            <div class="second-row">
                <div class="save-bhakthi flex flex-row">
                    <div class="slogan">{{ "MAIN_PAGE_TEXT.ramaSeve" | translate }}</div>
                    <div class="slogan">{{ "MAIN_PAGE_TEXT.ramaBhakti" | translate }}</div>
                </div>
            </div>
            <fieldset class="third-row">
                <legend class="legend">
                    <div class="bullet"></div>
                    <div class="elig-header">
                        {{ "MAIN_PAGE_TEXT.guideline" | translate }}
                    </div>
                    <div class="bullet"></div>
                </legend>

                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.elig1" | translate }}</div>
                </div>
                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.elig2" | translate }}</div>
                </div>
                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.elig3" | translate }}</div>
                </div>
                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.elig4" | translate }}</div>
                </div>

            </fieldset>

            <fieldset class="third-row">
                <legend class="legend">
                    <div class="bullet"></div>
                    <div class="elig-header">
                        {{ "MAIN_PAGE_TEXT.adminGuide" | translate }}
                    </div>
                    <div class="bullet"></div>
                </legend>

                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.ag1" | translate }}</div>
                </div>
                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.ag2" | translate }}</div>
                </div>
                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.ag3" | translate }}</div>
                </div>
                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.ag4" | translate }}</div>
                </div>
                <div class="eligibility-content flex">
                    <div><img src="/assets/bullet.png" alt="" /></div>
                    <div>{{ "MAIN_PAGE_TEXT.ag5" | translate }}</div>
                </div>

            </fieldset>
            <div class="btn-group">
                <p-button class="btn-primary" (click)="register()">{{ "MAIN_PAGE_TEXT.registerNow" | translate }}</p-button>
            </div>
        </div>

        
    </div>
</div>