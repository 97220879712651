import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-file-image-viewer',
  templateUrl: './file-image-viewer.component.html',
  styleUrls: ['./file-image-viewer.component.scss']
})
export class FileImageViewerComponent {
  safeFileUrl: SafeResourceUrl;

  constructor( private dialogConfig: DynamicDialogConfig,
    public restoreRef: DynamicDialogRef,
    private sanitizer: DomSanitizer
    ){
      this.safeFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dialogConfig.data);

    }
  
}
