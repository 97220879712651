import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonServiceService } from "src/app/services/common/common-service.service";
import { LocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { LoginService, UserFlow } from "src/app/services/login/login.service";
import { KalashotsavaService } from "../../services/kalashotsava.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() showKalash: boolean = true;
  @Input() showProfile: boolean = false;
  @Input() showRegisterLoginBtns: boolean = false;
  showOptions = false;
  showSevas = false;
  isLoggedIn = false;
  isMobile!: boolean;
  userNumber = this.loginService.getLoggedInUserDetail()?.userNumber;
  userName = this.loginService.getLoggedInUserDetail()?.name;
  isAdmin = false;

  constructor(
    private loginService: LoginService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private router: Router,
    private commonService: CommonServiceService,
  ) {
    translate.setDefaultLang(this.commonService.selectedLanguage);
    translate.use(this.commonService.selectedLanguage);
    if (this.router.url.includes("/admin")) {
      this.isAdmin = true;
    }
  }

  ngOnInit(): void {
    const accessToken = this.localStorageService.getAccessToken();
    if (accessToken && (this.showRegisterLoginBtns || this.showProfile)) {
      this.checkIfSessionIsActive();
    }
  }

  checkIfSessionIsActive() {
    this.loginService.refreshAuthToken().subscribe({
      next: (res) => {
        this.loginService.setAuthToken(res.data.accessToken);
        this.loginService.setRefreshToken(res.data.refreshToken);
        this.isLoggedIn = true;
      },
      error: (err) => {
        this.loginService.clearDataAfterLogout();
        this.isLoggedIn = false;
      },
    });
  }

  @HostListener("document:click", ["$event"])
  handleClick(event: Event) {
    const clickedInsideProfileDiv = (event.target as HTMLElement).closest(
      "#profile-div"
    );
    const clickedInsideSevaDiv = (event.target as HTMLElement).closest(
      "#seva-div"
    );

    if (!clickedInsideProfileDiv && !clickedInsideSevaDiv) {
      this.showOptions = false;
      this.showSevas = false;
    }
  }

      
  toggleLanguage(){
    const newLang = this.commonService.selectedLanguage === 'en' ? 'hi' : 'en';
    this.translate.use(newLang);
    this.commonService.selectedLanguage = newLang;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any): void {
    this.checkScreenWidth();
  }

  private checkScreenWidth(): void {
    this.isMobile = window.innerWidth < 940;
  }

  onClickProfile() {
    this.showOptions = !this.showOptions;
  }

  moreOptions() {
    this.showSevas = !this.showSevas;
  }

  register() {
    this.loginService.userFlow = UserFlow.REGISTRATION;
    this.router.navigateByUrl("/register");
  }

  login() {
    this.loginService.userFlow = UserFlow.LOGIN;
    this.router.navigateByUrl("/login");
  }

  logout() {
    this.loginService.logout().subscribe({
      next: (response) => {
        setTimeout(() => {
          this.isLoggedIn = false;
          if (this.isAdmin) {
            this.router.navigateByUrl("/admin/login");
          } else {
            this.router.navigateByUrl("/home");
          }
        });
      },
    });
  }

  navigateToKalashotsav() {
    this.router.navigateByUrl("/kalashotsava/booking");
  }

  navigateToOthers() {
    this.router.navigateByUrl("/other-sevas");
  }

  navigateToHistory() {
    //navigate to user successful payment
    // this.router.navigateByUrl("/kalashotsava/booking");
  }

  navigateToProcesses() {
    this.router.navigateByUrl("/kalashotsava");
  }

  routeToHome() {
    this.router.navigateByUrl("/home");
  }
}
