import { Injectable } from '@angular/core';
import { MenuName, MenuStatus, SideMenu, SideMenuService } from './side-menu.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from 'src/app/services/common/common-service.service';
import { LoginService } from 'src/app/services/login/login.service';
import { TranslateService } from '@ngx-translate/core';

export enum KalashotsavaStatus {
  INITIATE = "INITIATED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  BOOKED = "BOOKED",
  PAYMENT = "PAYMENT"
}

export enum DocumentStatus {
  SUBMIT = "SUBMIT",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  PAYMENT = "PAYMENT"
}

export interface DonationDetails {
  documentDetailId: string,
  donationGivenTo: string,
  donationCategory: string,
  donationAmount: string,
  donatedDate: string,
  documentName: string,
  photosFleName: string,
  documentPath: string,
  photosFilePath: string,
  noOfBeneficiaries: number,
  documentStatus: DocumentStatus | string,
  reasonForRejection?: string
}
@Injectable({
  providedIn: 'root'
})
export class KalashotsavaService {

  BASE_URL = environment.apiUrl
  sideMenu: SideMenu[] = [
    { name: MenuName.UPLOAD_DOCUMENT, status: MenuStatus.ACTIVE },
    // { name: MenuName.AVAILABLE_SLOTS, status: MenuStatus.INACTIVE },
    // { name: MenuName.PAYMENT, status: MenuStatus.INACTIVE },
  ]

  donationCategory=[
    {name:'Housing',value:'HOUSING'},
    {name:'Medical',value:'MEDICAL'},
    {name:'Education',value:'EDUCATION'},
    {name:'Gau Seva',value:'GAU_SEVA'},
    {name:'Others',value:'OTHERS'}
  ]

  kalashotsavaStatus: KalashotsavaStatus = KalashotsavaStatus.INITIATE

  constructor(private sideMenuService: SideMenuService,
    private http: HttpClient,
    private loginService: LoginService,
    ) {
     }

     get userId(){
      return this.loginService.getLoggedInUserDetail().userId
     }

     private receiptId: HTMLElement |string|null = '';

     setReceiptId(id:HTMLElement| string | null) {
       this.receiptId = id;
     }
   
     getReceiptId() {
       return this.receiptId;
     }

  setSideMenu() {
    this.sideMenuService.setSideMenus(this.sideMenu)
  }

  setAvailableSlotsActive() {
    this.sideMenu[0].status = MenuStatus.COMPLETED
    this.sideMenu[1].status = MenuStatus.ACTIVE
    this.sideMenuService.setSideMenus(this.sideMenu)
  }

  setPaymentActive() {
    this.sideMenu[0].status = MenuStatus.COMPLETED
    this.sideMenu[1].status = MenuStatus.COMPLETED
    this.sideMenu[2].status = MenuStatus.ACTIVE
    this.sideMenuService.setSideMenus(this.sideMenu)
  }


addDocument(data:FormData){
  const headers = new HttpHeaders({
    'fileUpload': 'true',
  });
  let params = new HttpParams().set('userId', this.userId||'');
  const apiUrl = this.BASE_URL + '/api/ramarajya/documents/upload';
  return this.http.post<any>(apiUrl, data, { params, headers  });
}

uploadSupportDocument(data:FormData){
  const headers = new HttpHeaders({
    'fileUpload': 'true',
  });
  let params = new HttpParams();
  params = params.set('userId', this.userId);
  params = params.set('isTemplateDocument', true);

  const apiUrl = this.BASE_URL + '/api/ramarajya/documents/upload';
  return this.http.post<any>(apiUrl, data, { params, headers  });
}

confirmSlots(data:any){
  const apiUrl = this.BASE_URL + '/api/ramarajya/confirm-slots';
  return this.http.post<any>(apiUrl, data);
}

updateDocument(docId:any,data:FormData,reUpload:boolean){
  const headers = new HttpHeaders({
    'fileUpload': 'true',
  });
  let params = new HttpParams().set('reUpload', reUpload);
  const apiUrl = this.BASE_URL + '/api/ramarajya/documents/update/'+docId;
  return this.http.put<any>(apiUrl, data, { headers ,params });
}

fetchAllDocument(){
  let params = new HttpParams().set('userId', this.userId||'');
  const apiUrl = this.BASE_URL + '/api/ramarajya/documents/fetch-all';
  return this.http.get<any>(apiUrl, {params });
}

fetchUserDetails(){
  let params = new HttpParams().set('userId', this.userId||'');
  const apiUrl = this.BASE_URL + '/api/ramarajya/user-details';
  return this.http.get<any>(apiUrl, {params });
}

viewDocument(docId:any){
  const apiUrl = this.BASE_URL + '/api/ramarajya/documents/view?filePath='+docId;
  return this.http.get<any>(apiUrl);
}

deleteDocument(donationId:any){
  const apiUrl = this.BASE_URL + '/api/ramarajya/documents/delete/'+donationId;
  return this.http.delete<any>(apiUrl);
}

submitDocument(applicationDetailId:any){
  const params={
    applicationDetailId:applicationDetailId
  }
  const apiUrl = this.BASE_URL + '/api/ramarajya/documents/submit';
  return this.http.put<any>(apiUrl,{}, {params });
}

fetchAvailableSlot(){
  const apiUrl = this.BASE_URL + '/api/ramarajya/fetch-available-slots';
  return this.http.get<any>(apiUrl);
}

fetchDonorBeneficiariesCount(){
  const apiUrl = this.BASE_URL + '/api/ramarajya/donor-beneficiary-count';
  return this.http.get<any>(apiUrl);
}



}
