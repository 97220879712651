<div class="confirmation-modal-component">
    <div  class="container">
        <div class="sub-title">
            {{subTitle}}
        </div>
        <div class="home-btn">
            <p-button class="btn-secondary" (click)="no()">{{'BUTTON.no' | translate}}</p-button>
            <p-button class="btn-primary" (click)="yes()">{{'BUTTON.yes' | translate}}</p-button>
          </div>
    </div>
</div>