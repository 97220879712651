import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'amount',
})
export class AmountPipe implements PipeTransform {
  constructor() {}

  transform(value: number | string | null): unknown {
    if(value){
      value=value.toLocaleString('en-IN');
    }
    return value
  }
}
