import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth-guard.service';
import { TermsAndConditionsComponent } from './home-page/components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './home-page/components/privacy-policy/privacy-policy.component';
import { TrademarksComponent } from './home-page/components/trademarks/trademarks.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home-page/home-page.module').then(m => m.HomePageModule),
  },
  {
  path: 'i-am-interested',
  loadChildren: () =>
      import('./i-am-interested/i-am-interested.module').then(m => m.IAmInterestedModule),
    },
  {
    path: 'kalashotsava',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./kalashotsava/kalashotsava.module').then(m => m.KalashotsavaModule),
  },
  {
    path: 'other-sevas',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./other-sevas/other-sevas.module').then(m => m.OtherSevasModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'terms-and-conditions',
   component: TermsAndConditionsComponent, 
  },
  {
    path: 'privacy-policy',
   component: PrivacyPolicyComponent, 
  },
  {
    path: 'trademark',
   component: TrademarksComponent, 
  },
  { path: '**', 
  loadChildren: () =>
  import('./home-page/home-page.module').then(m => m.HomePageModule),
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
