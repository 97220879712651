<app-policies-header></app-policies-header>
<div class="cookies-policy">
  <div class="heading">Cookies Policy</div>
  <div class="content">
    Our……………(Trust Name) websites are more user-friendly because of the use of
    cookies. Learn about the primary cookie kinds that we use and their
    purposes. Cookies are small text files that are used to recognize repeat
    visitors, facilitate the visitor’s ongoing access to and use of the site,
    allow a site to track usage behavior and compile aggregate data that helps
    us better understand our customers and deliver a more targeted user
    experience. Cookies are not programs that come onto visitor’s system and
    damage files. Generally, cookies work by assigning a unique number to the
    visitor that has no meaning outside the assigning site. By tuning our
    content to meet your requirements, we can improve your next visit by using
    the information from your past visits to our website. Our main objective for
    using cookies is to improve user experience on our websites and mobile
    applications, as well as to track anonymous user activity. In general,
    sensitive or personally identifying information like your name, address, or
    credit card number is not stored by our cookies. In the unlikely event that
    our websites use cookies to store personal information about you, we encrypt
    the personal data to prevent unauthorized use by anyone else. If you don’t
    want information to be collected through the use of cookies, there is a
    simple procedure in most browsers that allows you to deny or accept the
    cookie feature. Blocking cookies will have a negative impact on the
    performance of the third-party tools and consequently you may not be able to
    use some of the features of our website. Here is a list of the main types of
    cookies that we use, along with their respective purposes:
  </div>
  <div class="sub-heading">SESSION COOKIES</div>
  <div class="content">
    Session cookies remain only as long as a browsing session is active and are
    intended to avoid user inconvenience during browsing. These cookies link
    user actions during a browser session and expire at the end of the session;
    they also help users navigate the website and access secure areas when
    logged in.
  </div>

  <div class="sub-heading">SOCIAL MEDIA COOKIES</div>
  <div class="content">
    If you log in to our websites using credentials from social media or another
    third party, that third party organization may place a cookie that helps it
    identify you. That cookie might be used by the outside entity for its own
    objectives. When you access the organization's websites or use its
    applications, you might also see advertisements and material from us. In
    addition, the social network that made the button will keep track of your
    activity if you use a social media sharing button or widget on our website.
    For information on how each social networking platform uses cookies, how it
    tracks users from our sites, and how to manage these cookies and buttons,
    please refer to their privacy and data protection policies.
  </div>

  <div class="sub-heading">PERSISTENT COOKIE</div>
  <div class="content">
    Cookies that remain on a user's device after browsing session ends are known
    as persistent cookies. It aids in remembering the user's choices or
    behavior. Persistent cookies serve multiple objectives, including saving the
    language and regional preferences of the visitor at the conclusion of each
    browsing session. Utilizing the services of a third-party analytics service
    provider, we analyze cookies to perform behavioral analysis to improve user
    convenience and experience while also offering visitors relevant and
    tailored information. Cookies may be accepted by default in your browser,
    depending on its type and settings. You may change the settings of your
    browser to delete existing cookies or prevent future cookies from being
    automatically accepted. Some sections and features of our website might not
    work if you disable cookies. Visit www.aboutcookies.org to find out more
    information about cookies.
  </div>

  <div class="sub-heading">THIRD PARTY COOKIES</div>
  <div class="content">
    If you click on a hyperlink from ……………. trust website to any third-party
    websites (for example, if you ‘share’ content from ……………………… trust ’s
    websites with friends or colleagues through social networks), you may be
    sent cookies from those third-party websites. …………….. Trust India has no
    control over the privacy and cookie policies of third-party websites. For
    additional details regarding third-party cookies and how to control them,
    kindly visit their websites.
  </div>
</div>
<app-policies-footer></app-policies-footer>
