import { Component, Input } from "@angular/core";
import { MessageService } from "primeng/api";

export enum ToastType{
  SUCCESS='success',
  ERROR='error',
  EMPTY=''
}
export interface ToastDetails {
  toastType?: ToastType;
  key?: string;
  severity?: string;
  summary?: string;
  detail?: string;
  closable?: boolean;
  sticky?: boolean;
  life?: number;
}

@Component({
  selector: "app-toaster",
  templateUrl: "./toaster.component.html",
  styleUrls: ["./toaster.component.scss"],
  providers: [MessageService],
})
export class ToasterComponent {
  toastType: string = "";
  @Input("toastDetails") set toastDetails(inValue: ToastDetails) {
    this.toastType = inValue.toastType ? inValue.toastType : "";
    this.messageService.add({
      key: "tc",
      severity: "success",
      summary: "",
      detail: inValue.detail,
      closable: inValue.closable,
      sticky: inValue.sticky,
      life: 3000,
    });
  }

  constructor(private messageService: MessageService) {}
}
