import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageRoutingModule } from './home-page-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HomePageComponent } from './home-page.component';
import { RegitrationFormComponent } from './components/regitration-form/regitration-form.component';
import { MainHomePageComponent } from './pages/main-home-page/main-home-page.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FooterComponent } from './pages/footer/footer.component';
import { PoliciesHeaderComponent } from './components/policies-header/policies-header.component';
import { PoliciesFooterComponent } from './components/policies-footer/policies-footer.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { TrademarksComponent } from './components/trademarks/trademarks.component';
import { InstructionGuidlinesComponent } from './pages/instruction-guidlines/instruction-guidlines.component';

@NgModule({
  declarations: [
    HomePageComponent,
    RegitrationFormComponent,
    MainHomePageComponent,
    TermsAndConditionsComponent,
    FooterComponent,
    PoliciesHeaderComponent,
    PoliciesFooterComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    TrademarksComponent,
    InstructionGuidlinesComponent
  ],
  imports: [CommonModule, HomePageRoutingModule, SharedModule],
  exports: [
    PoliciesHeaderComponent,
    PoliciesFooterComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    TrademarksComponent
  ],
  providers: [ReCaptchaV3Service],

})
export class HomePageModule { }
