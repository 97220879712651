import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  subTitle:string=''
  constructor( 
    private dialogConfig: DynamicDialogConfig,
  public restoreRef: DynamicDialogRef,
    ){
      this.subTitle=this.dialogConfig.data
    }

  yes(){
    this.restoreRef.close('yes')

  }
  no(){
    this.restoreRef.close('no')

  }
}
