import { Component } from '@angular/core';

@Component({
  selector: 'app-trademarks',
  templateUrl: './trademarks.component.html',
  styleUrls: ['./trademarks.component.scss']
})
export class TrademarksComponent {

}
