import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-policies-footer',
  templateUrl: './policies-footer.component.html',
  styleUrls: ['./policies-footer.component.scss']
})
export class PoliciesFooterComponent {

  constructor(private router: Router){}

  scrollToTop(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  }
  termsAndConditions(){
    this.router.navigateByUrl('/terms-and-conditions')
    this.scrollToTop()
  }

  privacyPolicy(){
    this.router.navigateByUrl('/privacy-policy')
    this.scrollToTop()
  }

  cookiesPolicy(){
    this.router.navigateByUrl('/cookies-policy')
    this.scrollToTop()
  }

  trademarks(){
    this.router.navigateByUrl('/trademark')
    this.scrollToTop()
  }

}
