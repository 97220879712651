import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonServiceService } from 'src/app/services/common/common-service.service';
import { LoginService, UserFlow } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-policies-header',
  templateUrl: './policies-header.component.html',
  styleUrls: ['./policies-header.component.scss']
})
export class PoliciesHeaderComponent {

  @Input() isInstructionPage=false
  
  constructor(private translate: TranslateService , private loginService: LoginService, private router : Router,private commonService : CommonServiceService){
    translate.setDefaultLang(this.commonService.selectedLanguage);
    translate.use(this.commonService.selectedLanguage);
  }

  register() {
    this.loginService.userFlow = UserFlow.REGISTRATION;
    this.router.navigateByUrl("/register");
  }

  login() {
    this.loginService.userFlow = UserFlow.LOGIN;
    this.router.navigateByUrl("/login");
  }

routeToHome(){
  this.router.navigateByUrl('/home')
}
}
