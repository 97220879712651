import { Component, Input, OnInit } from "@angular/core";
import {
  MenuName,
  MenuStatus,
  SideMenu,
  SideMenuService,
} from "../../services/side-menu.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  @Input() pageType: string = "";

  sideMenus: SideMenu[] | null = [];
  selectedSideMenu: string = "";

  constructor(
    private sideMenuService: SideMenuService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sideMenuService.sideMenusObservable.subscribe((data) => {
      this.sideMenus = data;
      if (this.pageType === "admin" && this.sideMenus?.length) {
        setTimeout(() => {
          this.checkForSelectedMenu();          
        });
      }
    });
  }

  checkForSelectedMenu() {
    switch (this.router.url.split("/").pop()) {
      case "application":
        this.selectedSideMenu = MenuName.APPLICATION;
        this.sideMenuService.setSelectedSideMenu(MenuName.APPLICATION);
        break;
      case "booking-status":
        this.selectedSideMenu = MenuName.PAYMENT_STATUS;
        this.sideMenuService.setSelectedSideMenu(MenuName.PAYMENT_STATUS);
        break;
      case "reserve-slots":
        this.selectedSideMenu = MenuName.RESERVE_SLOT;
        this.sideMenuService.setSelectedSideMenu(MenuName.RESERVE_SLOT);
        break;
      case "other-voluntary-services":
        this.selectedSideMenu = MenuName.OTHER_VOLUNTARY_SERVICE;
        this.sideMenuService.setSelectedSideMenu(MenuName.OTHER_VOLUNTARY_SERVICE);
        break;
      case "bypass-login":
        this.selectedSideMenu = MenuName.BYPASS_LOGIN;
        this.sideMenuService.setSelectedSideMenu(MenuName.BYPASS_LOGIN);
        break;

      default:
        this.selectedSideMenu = MenuName.APPLICATION;
        this.router.navigate(["/admin/applications"]);
        break;
    }
  }

  getMenuClass(menu: SideMenu): object {
    return {
      "is-complete": menu.status === MenuStatus.COMPLETED,
      "is-active is-active-menu":
        menu.status === MenuStatus.ACTIVE ||
        (this.pageType === "admin" && menu.name === this.selectedSideMenu),
      "is-inactive": menu.status === MenuStatus.INACTIVE,
      "is-selectable": menu.status === MenuStatus.SELECTABLE,
    };
  }

  isComplete(status: MenuStatus) {
    return status === MenuStatus.COMPLETED;
  }

  onMenuClick(menuName: string) {
    this.selectedSideMenu = menuName;
    this.sideMenuService.setSelectedSideMenu(menuName);
    if (this.pageType === "admin") {
      this.routePages();
    }
  }

  routePages() {
    switch (this.selectedSideMenu) {
      case MenuName.APPLICATION:
        this.router.navigate(["/admin/applications"]);
        break;
      case MenuName.PAYMENT_STATUS:
        this.router.navigate(["/admin/booking-status"]);
        break;
      case MenuName.RESERVE_SLOT:
        this.router.navigate(["/admin/reserve-slots"]);
        break;
      case MenuName.OTHER_VOLUNTARY_SERVICE:
        this.router.navigate(["/admin/other-voluntary-services"]);
        break;
      case MenuName.BYPASS_LOGIN:
        this.router.navigate(["/admin/bypass-login"]);
        break;

      default:
        this.router.navigate(["/admin/applications"]);
        break;
    }
  }
}
