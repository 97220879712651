<app-policies-header></app-policies-header>
<div class="trademarks">
  <div class="heading">Trademarks Use Policy</div>
  <div class="content">
    This <span><a href="https://ramarajya.co.in" target="_blank">https://ramarajya.co.in</a></span> website
    (“Site”) is owned and operated by <span>Pejavar Mutt</span>.
    ("Trust"). In this Trademark Use Policy, “we” and “our” refer to
    Trust.<br><br> We have invested significant time and resources to protect
    our trademarks, service marks and logos. Trademarks are important for
    establishing and protecting our brand/Trust’s identity, but they are fragile
    rights that can be lost through misuse.<br><br> This Trademarks Use Policy
    (“Policy”) sets forth Trust’s policy for third party use of and reference to
    Trust’s names, brands, trade dress, trademarks, service marks, logos,
    slogans or other identifiers of Trust (“Trust’s Marks”).<br><br> The Site, all
    content present on this Site, including all the software, text, images,
    graphics, video and audio used on this Site, is exclusive and proprietary
    material owned by the Trust. Site and its original content (excluding
    Content provided by users), features and functionality are and will remain
    the exclusive property of Trust and its licensors. The Trust’s Marks belong
    to and are owned by the Trust. Any contents of this Site is protected by
    copyright, trademark, and other applicable laws of India. The Trust’s Marks
    shall not be used in any manner whatsoever and shall not be used in
    connection with any product or service without the prior written consent of
    Trust.<br><br>All rights not expressly granted herein are reserved. Unauthorized
    use of the materials appearing on this Site may violate copyright,
    trademark, and other applicable laws, and could result in criminal or civil
    penalties.<br><br>The Trust may monitor access to the Site.
  </div>
</div>
<app-policies-footer></app-policies-footer>