<app-policies-header></app-policies-header>
<div class="privacy-policy">
  <div class="heading">Privacy Policy</div>
  <div class="content">
    This website (“Site”) is owned and operated by
    <span>Pejavar Mutt</span>(“Trust”). In this Privacy Policy, “we”,
    “us” and “our” refer to Samithi.<br />
    The term “ you”, “your” shall mean the user of the Site. <br /><br />We
    recognize that you may be concerned about the information we collect from
    you through our website and how we treat that information. We are committed
    to ensuring that your privacy is protected. <br /><br />This Privacy Policy
    is effective from <span>22 December 2023</span>. This policy describes what
    information we collect from you, why we collect it, how it is used,
    protected and retained as well as your choices regarding this information.
  </div>
  <div class="sub-heading">PERSONAL INFORMATION</div>
  <div class="content">
    We respect your privacy when you visit our website. At our site, we do not
    collect personally identifiable information unless you provide it to us
    voluntarily. To access the content of the website you need not register or
    provide your personal information. However, we gather certain personally
    identifiable data under specific circumstances. We do not sell or trade such
    information collected to third parties. Also, we do not share such
    information with third parties unless authorized by the person submitting
    the information or when required by law.
  </div>

  <!-- <div class="sub-heading">REGISTRATION</div>
  <div class="content">
    You are required to register on our Site, if you opt for any Sevas, if you
    opt to access any third-party link or participate in any event, pooja, as
    per the registration criteria mentioned in the Site. When you register
    through our online registration page, we collect your name, mobile number,
    e-mail address, necessary documents, postal address and zip/postal code. We
    use your personal information only for the purposes for which it is meant.
    However, you can also opt to receive our communications related to our
    Trust, events and various other opportunities for volunteering in our Trust,
    and we use your personal data for these communications.
  </div> -->

  <!-- <div class="sub-heading">SEVA</div>
  <div class="content">
    If you opt for any seva, you can specify in whose name the seva should be
    offered. In such cases, we collect additional details like the sevakarta’s
    name, his relationship with you, his rashi, nakshatra and gotra which are
    needed to perform sankalpa in the sevakarta’s name.
  </div> -->

  <!-- <div class="sub-heading">EXTERNAL LINKS</div>
  <div class="content">
    Our website may contain links to other websites of interest. The fact that
    we have provided a link to a site is not a representation, an endorsement,
    authorization, sponsorship, or affiliation with respect to such site, its
    contents, its owners, or its providers unless we expressly state otherwise.
    There are risks associated with using any information, software, or products
    found on the internet, and we caution you to make sure that you understand
    these risks before retrieving, using, relying upon, or purchasing anything
    via the internet. In case, you opt for any seva, purchase, donation or any
    payment through the external links such seva, purchase, donation or any
    payment through external links will be as per the terms and privacy policy
    of that particular external website. These other sites may collect or
    solicit personal data or send their own cookies to your computer. Please be
    aware that we are not responsible for your activities on those websites and
    the privacy practices of those sites regarding the collection and use of
    your personal information.
  </div> -->

  <div class="sub-heading">Enquiries (Contact Us)</div>
  <div class="content">
    When you send us an enquiry through the ‘Contact Us’ page, we collect your
    name and email id along with your enquiry to communicate with you and answer
    your questions.
  </div>

  <div class="sub-heading">
    Google Analytics/ Google Tag Manager (for Analytics)
  </div>
  <div class="content">
    We use Google Analytics/ Google Tag Manager to analyze and evaluate the use
    and performance of our website in order to improve your online experience
    and take up necessary steps needed to further our objectives. Google
    Analytics/ Google Tag Manager collects non-personal data that may include
    your IP address, geographical location, browser type and version, operating
    system, referral source, length of visit, page views and website navigation
    paths, as well as information about the timing, frequency and pattern of
    your website visit. This data is used in research and analytics to determine
    the popularity of our website content, measure the effectiveness of
    advertising campaigns, analyze site traffic and trends, and to understand
    the online behaviors and interests of our website visitors. For more
    information on the privacy practices of Google, please visit the Google
    Privacy Terms web page: <a href=" https://policies.google.com/privacy?hl=en" target="_blank">
      https://policies.google.com/privacy?hl=en</a><br> We also
    encourage you to review the Google’s policy for safeguarding your data:
    <a href="https://support.google.com/analytics/answer/6004245" target="_blank">
      https://support.google.com/analytics/answer/6004245</a>
  </div>

  <div class="sub-heading">SECURITY</div>
  <div class="content">
    We are committed to ensure that the information you share with us is secure.
    In order to protect your data from unauthorized access or disclosure, or
    unlawful processing and against accidental loss, destruction or damage we
    have put in place suitable physical, electronic and managerial procedures.
  </div>

  <div class="sub-heading">AMENDMENTS TO THIS POLICY</div>
  <div class="content">
    If we decide to change our privacy policy, we will post those changes to
    this privacy statement, so you are always aware of what information we
    collect, how we use it, and under what circumstances, if any, we disclose
    it. We will use information in accordance with the privacy policy under
    which the information was collected. You are advised to review this Privacy
    Policy periodically for any changes. Changes to this Privacy Policy are
    effective when they are posted on this page. If we decide to use your
    personally identifiable information in a manner significantly different from
    that stated at the time of collection, we will notify you through e-mail and
    will not use your information in the new manner unless your express
    permission is granted. However, if you have opted out of all communication
    with the site, or deleted/deactivated your account, then you will not be
    contacted, nor will your personal information be used in this new manner.
  </div>

  <div class="sub-heading">DATA RETENTION</div>
  <div class="content">
    We seek to retain your personal information only as long as necessary to
    fulfil the purposes described in this policy unless a longer retention
    period is required by law or regulations.
  </div>

  <div class="sub-heading">RIGHTS</div>
  <div class="content">
    You have certain rights with respect to the personal information we collect
    about you. Upon your request, we tell you what information we hold about
    you, we rectify any incomplete or inaccurate information and also restrict
    the use of your information. We will make reasonable efforts to delete your
    information if you ask us to do so, unless we are otherwise required to keep
    it.
  </div>

  <div class="sub-heading">SHARING</div>
  <div class="content">
    Though we make a good faith effort to preserve your privacy, we may need to
    disclose your personal information when required by law wherein we have a
    good faith belief that such action is necessary to comply with a current
    judicial proceeding, a court order or legal process served on our website or
    when needed to protect our rights, privacy, safety, property, donors, or
    users; and when necessary to enforce our terms of service.
  </div>

  <div class="sub-heading">CHILDREN'S PRIVACY</div>
  <div class="content">
    Our Site is not intended for use by children under the age of 18
    (“Children”).<br> We do not knowingly collect personally identifiable
    information from Children under 18. If you become aware that a child has
    provided us with Personal Data, please contact us. If we become aware that
    we have collected Personal Data from Children without verification of
    parental consent, we take steps to remove that information from our servers.
  </div>

  <div class="sub-heading">CONTACT US</div>
  <div class="content">
    If you have any questions about this Privacy Policy, please contact us: By
    email:
    <span><a href="mailto:subrahmanya736@gmail.com" target="_blank">subrahmanya736@gmail.com</a></span>.
  </div>
</div>
<app-policies-footer></app-policies-footer>