import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTextOnly]'
})
export class TextOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const inputValue = this.el.nativeElement.value;

    // Check if the input value starts with a space
    if (inputValue.startsWith(' ')) {
      // Remove the leading space
      this.el.nativeElement.value = inputValue.trimLeft();
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: any) {
    const allowedCharacters = /[a-zA-Z\s]/;

    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }
}
