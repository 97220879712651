<div class="header-content flex space-between align-items-center ">
        <img *ngIf="showKalash" src="assets/i-am-interested/images/kalasha.webp" alt="" class="logo cursor-pointer" (click)="routeToHome()">
        <div *ngIf="!showKalash"></div>
   <div class="flex gap-4 justify-content-center align-items-center">
    <div class="btn-group flex flex-row gap-4" *ngIf = "showRegisterLoginBtns && !isLoggedIn">
        <p-button class="btn-primary" (click)="register()"> {{'BUTTON.register' | translate}}</p-button>
        <p-button class="btn-primary" (click)="login()">{{'BUTTON.login' | translate}}</p-button>
        <div>
            <img src="assets/home-page/rightside cta.svg" alt="" (click)="toggleLanguage()" class="translate-icon">
        </div>
    </div>
    <div class="sevas flex flex-row gap-4 align-items-center" *ngIf = "showRegisterLoginBtns  && isLoggedIn && !isMobile">
        <div class="other-seva-btn-black"  (click)="navigateToKalashotsav()" > {{'LABEL.kalashotsava' | translate}}</div>
        <!-- <div class="other-seva-btn-black" (click)="navigateToOthers()">{{'LABEL.otherVoluntaryServices' | translate}}</div> -->
        <div>
            <img src="assets/home-page/rightside cta.svg" alt="" (click)="toggleLanguage()" class="translate-icon">
        </div>
    </div>
    <div (click)="onClickProfile()" *ngIf="isLoggedIn" class="profile-container" id="profile-div">
        <img src="assets/home-page/profile-icon.svg" alt="" *ngIf="showProfile"  class="profile-icon">
        <div class="showOptions flex flex-column gap-1 justify-content-around" *ngIf="showOptions">
            <div class="arrow-up-1"></div>
           <div class="profile" >
            <div class="user-name">{{userName}}</div>
           <div class="user-number">{{userNumber}}</div>
           </div>
        <ng-container *ngIf="!isAdmin">
            <!-- <div class="other-options cursor-pointer" (click)="navigateToHistory()">{{'BUTTON.history' | translate}}</div> -->
            <div class="other-options cursor-pointer" (click)="navigateToProcesses()">{{'BUTTON.process' | translate}}</div>
        </ng-container>
           <div class="other-options cursor-pointer"  (click)="logout()">{{'BUTTON.logout' | translate}}</div>
        </div>
    </div>
    <div class="seva-container cursor-pointer" *ngIf="isLoggedIn && isMobile"  id="seva-div">
        <img src="/assets/more-vertical.png" alt="" (click)="moreOptions()">
        <div class="show-sevas flex flex-column gap-1 justify-content-around" *ngIf="showSevas">
            <div class="arrow-up-2"></div>
           <div class="other-options cursor-pointer"  (click)="navigateToKalashotsav()">{{'LABEL.kalashotsava' | translate}}</div>
           <!-- <div class="other-options cursor-pointer" (click)="navigateToOthers()">{{'BUTTON.otherVoluntaryServices' | translate}}</div> -->
        </div>
    </div>
   </div>
</div>
